import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { RegisterService } from '../register.service';
import { environment } from '../../../environments/environment';
import * as Cookies from 'js-cookie';

@Injectable()
export class LanguageService {

  constructor(private http: HttpClient,
              private registerService: RegisterService,
              private translateService: TranslateService) {
  }


  useLanguage(lang: string) {
    this.translateService.use(lang);
    this.setCookieLanguage(lang);
  }

  setCookieLanguage(language: string) {
    const numberOfDays = 365;
    Cookies.set('language', language, { expires: numberOfDays, domain: `${environment.COOKIE_DOMAIN}` });
  }

  getCookieLanguage() {
    return Cookies.get('language');
  }

  setLangToUser() {
    const lang = this.translateService.currentLang;
    this.registerService.updateUser({ language: lang });
  }

  selectLanguageBasedOnBrowserSettings(): string {
    let language = this.translateService.getBrowserLang();
    language = language.match(/en|es/) ? language : 'en';
    return language;
  }

}
