import { Component } from '@angular/core';

@Component({
  selector: 'cc-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent {

  constructor() { }

}
