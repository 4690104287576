import { Injectable } from '@angular/core';

@Injectable()
export class GtmService {

  constructor() {
    if (! Object.hasOwnProperty.call(window, 'dataLayer')) {
      console.error('`window.dataLayer` is not defined.');
    }
  }

  push(data: string) {
    window['dataLayer'].push({event: data});
  }
}
