import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateConfig } from '../config/translate-config';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forRoot(TranslateConfig),
    SharedModule
  ],
  exports: [],
  declarations: []
})
export class MainModule { }
