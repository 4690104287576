import {Injectable} from '@angular/core';
import {IConfigResponse} from './app-config.interface';
import {HttpClient} from '@angular/common/http';
import {generated} from '../../environments/environment.generated';
import {appConfigDefault} from './app-config.default.values';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';


@Injectable()
export class AppConfig implements IConfigResponse {
  public sentryEnabled: boolean;
  public facebookAppId: string;
  public livechatClientId: string;
  public googleApiKey: string;
  public liveChatLicense: number;
  public amplitudeApiKey: string;
  public shoperAmplitudeApiKey: string;

  public commitHash: string = generated.commitHash;

  /* patterns in app-config.ts and user.invitation.html should be the same  pattern from: http://emailregex.com/*/
  /* RegExp modified by adding: [\s]* to allow empty characters at the beginning and the end of phrase */
  public emailPattern: RegExp = /^[\s]*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))[\s]*$/;

  constructor(private http: HttpClient) {}

  setConfig(config: IConfigResponse) {
    Object.keys(appConfigDefault).forEach((key) => {
      this[key] = config[key] || appConfigDefault[key];
    });
    return config;
  }

  initConfig(): Observable<IConfigResponse> {
    return this.http.get('/api/config').pipe(
      map((config: IConfigResponse) => this.setConfig(config))
    )
  }
}
