import { environment as envStaging } from "./environment.staging";

export const environment = {
  ...envStaging,
  production: true,
  INTERNATIONALIZATION_URL:
    "https://s3-eu-west-1.amazonaws.com/crazycall-channels-internationalization/sso/",
  COOKIE_DOMAIN: ".channels.app",
  SSO_URL: "https://sso.crazycall.com",
  CHANNELS_SSO_URL: "https://sso.channels.app",
  SHOPER_CONNECT_URL: "https://app.shoperconnect.pl",
  CHANNELS_URL: "https://my.channels.app",
  SHOPER_CONNECT_SSO_DOMAIN: "shoperconnect.pl",
};
