import { Injectable } from '@angular/core';
import { RegisterService } from '../shared/register.service';

@Injectable()
export class LoginService {

  constructor(private registerService: RegisterService) {
  }

  loginUser() {
    const loginForm = this.getLoginForm();
    const email = this.getEmail();
    const passwordInput = this.getPasswordInput();
    loginForm.appendChild(email);
    loginForm.appendChild(passwordInput);
    document.body.appendChild(loginForm);
    loginForm.submit();
  }

  getLoginForm() {
    const loginForm = document.createElement('form');
    loginForm.action = '/login';
    loginForm.method = 'POST';
    loginForm.style.display = 'none';
    return loginForm;
  }

  getEmail() {
    const email = document.createElement('input');
    email.name = 'email';
    email.setAttribute('type', 'hidden');
    email.value = this.registerService.getEmail();
    return email;
  }

  getPasswordInput() {
    const passwordInput = document.createElement('input');
    passwordInput.name = 'password';
    passwordInput.setAttribute('type', 'hidden');
    passwordInput.value = this.registerService.getPassword();
    return passwordInput;
  }
}
