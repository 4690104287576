import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterHelperService } from '../register/register-helper.service';
import { AccountService } from '../shared/validators/account/account.service';
import { EmailService } from '../shared/validators/email/email.service';
import { UtilsModule } from '../utils/utils.module';
import { ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { RegisterService } from '../shared/register.service';
import { RegisterRequestsService } from '../register/register-requests.service';
import { SocialAuthServiceConfig , SocialLoginModule } from '@abacritt/angularx-social-login';
import { AppConfig } from '../config/app-config';
import { provideSocialAuthService } from '../register/register-social/register-social.service.provider';
import { InviteGuard } from '../register/guards/invite.guard';
import { DetailsGuard } from '../register/register-3-steps/guards/details.guard';
import { CompanyGuard } from '../register/register-3-steps/guards/company.guard';
import { EmailGuard } from '../register/register-3-steps/guards/email.guard';
import { ErrorGuard } from '../register/guards/error.guard';
import { TranslateModule } from '@ngx-translate/core';
import { VerifyPhoneNumberService } from '../register/verify-phone-number/verify-phone-number.service';
import { VerifyPhoneNumberGuard } from '../register/verify-phone-number/verify-phone-number.guard';
import { NgProgressModule } from 'ngx-progressbar';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { BrowserAnimationsModule,  } from '@angular/platform-browser/animations';
import { RegisterAlternativeRequestsService } from './register-alternative-requests.service';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    UtilsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    SocialLoginModule,
    NgProgressModule,
    MatSelectModule,
    MatInputModule,
    BrowserAnimationsModule,
    SharedModule
  ],
  declarations: [],
  providers: [
    AccountService,
    EmailService,
    RegisterHelperService,
    RegisterService,
    RegisterRequestsService,
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: provideSocialAuthService,
      deps: [AppConfig]
    },
    InviteGuard,
    EmailGuard,
    DetailsGuard,
    CompanyGuard,
    ErrorGuard,
    VerifyPhoneNumberService,
    VerifyPhoneNumberGuard,
    RegisterAlternativeRequestsService
  ]
})
export class RegisterAlternativeModule {
}

