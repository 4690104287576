import { AbstractControl, ValidatorFn } from '@angular/forms';

const notAllowedCharacter = ['#', '&', '`', '{', '}'];

export function specialCharactersAccount(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } =>
    control.value && notAllowedCharacter.some((character) => control.value.toString().includes(character))
      ? {character: true}
      : null;
}

export function ValidateEmptyCharacters(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } =>
    control.value.trim() !== ''
      ? null
      : { empty: true };
}
