<form [formGroup]="resetForm"
      (ngSubmit)="resetPassword()"
      novalidate
      class="form-wrapper validate">
  <h2 [innerHtml]="'CHANNELS.RESET.SET_UP_PASSWORD' | translate"
      class="channels-reset-title"></h2>
  <p class="channels-reset-description" translate>CHANNELS.RESET.MESSAGE</p>

  <div class="alert alert-danger radius d-flex align-items-center mt-4"
       role="alert"
       *ngIf="resetForm.get('passwordConfirm').hasError('NotEqual') && resetForm.get('passwordConfirm').touched">
    <i class="material-icons text-danger">error</i>
    <span class="text-danger ml-2"
          translate>ERROR.MATCH</span>
  </div>

  <div class="channels-form-group">
    <input formControlName="password"
           class="form-control"
           type="password"
           id="password"
           [type]="hide ? 'password' : 'text'"
           [placeholder]="'PASSWORD' | translate"
           autofocus>
    <mat-icon matSuffix
              (click)="hide = !hide"
              [title]="(hide ? 'CHANNELS.REGISTER.SHOW' : 'CHANNELS.REGISTER.HIDE') | translate"
              [class.active]="!hide">
      {{'visibility'}}</mat-icon>
    <cc-input-errors [form]="resetForm"
                     inputName="password"
                     class="error-wrapper"
                     [errors]="[{'required':'ERROR.REQUIRED'},
                                {'minlength': 'ERROR.PASSWORD'}]"></cc-input-errors>
  </div>

  <div class="channels-form-group">
    <input formControlName="passwordConfirm"
           class="form-control"
           [type]="hide ? 'password' : 'text'"
           id="passwordConfirm"
           [placeholder]="'PASSWORD_CONFIRM' | translate"
           autofocus>

    <cc-input-errors [form]="resetForm"
                     inputName="passwordConfirm"
                     class="error-wrapper"
                     [errors]="[{'required':'ERROR.REQUIRED'},
                                {'minlength': 'ERROR.PASSWORD'}]"></cc-input-errors>
  </div>

  <button class="channels-button"
          [disabled]="resetForm.invalid"
          id="reset"
          translate>RESET.SUBMIT
  </button>
</form>

