import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as countryData from 'country-data'

@Injectable()
export class IpUserInfoService {

  private ipUserInfo: any = {};
  private isPromiseReady: Promise<any>;
  private defaultCountry = 'US';
  private countryData = countryData;

  constructor(private http: HttpClient) {
  }

  getIpInfo() {
    return this.isPromiseReady = new Promise<void>(resolve => {
      this.http.get('/geolocation')
        .subscribe((json) => {
          this.ipUserInfo = json;
          resolve();
        }, () => {
          resolve();
        });
    });
  }

  isReady() {
    return this.isPromiseReady;
  }

  getCountry() {
    return this.ipUserInfo.countryCode || this.defaultCountry;
  }

  getFullNameCountry() {
    return this.countryData.callingCountries.all.find(country => country.alpha2 == this.getCountry()).name;
  }

}
