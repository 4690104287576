import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { UntypedFormGroup } from '@angular/forms';
import { AllowOnly } from '../allow-only.directive';

@Component({
  selector: 'cc-material-input',
  templateUrl: './material-input.component.html',
  styleUrls: ['./material-input.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class MaterialInputComponent implements OnInit {
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() labelKey: string;
  @Input() type: string;
  @Input() placeholderKey: string;
  @Input() required = false;

  @Input() parentForm: UntypedFormGroup;
  @Input() controlName: string;
  @Input() errors: { key: string, messageKey: string }[] = [];

  @Input() fullWidth = false;
  @Input() allowOnly: AllowOnly = null

  constructor() {
  }

  ngOnInit() {
    if (this.required && !this.errors.some(({key}) => key === 'required')) {
      this.errors = [{key: 'required', messageKey: 'ERROR.REQUIRED'}, ...this.errors]
    }
  }

}
