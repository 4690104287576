import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { routes } from "./config/routes";
import { LoginModule } from "./login/login.module";
import { UtilsModule } from "./utils/utils.module";
import { RegisterModule } from "./register/register.module";
import { MainModule } from "./main/main.module";
import { AppConfig } from "./config/app-config";
import { ResetModule } from "./reset/reset.module";
import { HttpClientModule } from "@angular/common/http";
import { TranslateModule } from "@ngx-translate/core";
import { AppInitializer } from "./config/app-initializer";
import { SentryErrorHandler } from "./config/sentry-config";
import { NgProgressModule } from "ngx-progressbar";
import { LanguageService } from "./shared/services/language.service";
import { RegisterAlternativeModule } from "./register-alternative/register-alternative.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ChannelsModule } from "./channels/channels.module";
import { LivechatModule } from "./livechat/livechat.module";
import { MaterialModule } from "./material/material.module";
import { ShoperConnectModule } from "./shoper-connect/shoper-connect.module";
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule
} from "@abacritt/angularx-social-login";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true }),
    TranslateModule.forRoot(),
    ReactiveFormsModule,
    LoginModule,
    UtilsModule,
    RegisterModule,
    RegisterAlternativeModule,
    LivechatModule,
    ShoperConnectModule,
    MainModule,
    ResetModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ChannelsModule,
    MaterialModule,
    NgProgressModule,
    SocialLoginModule
  ],
  providers: [AppConfig, AppInitializer, SentryErrorHandler, LanguageService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '1041188948998-23qffm3nmknko0kbs4h90sktahd5ulbp.apps.googleusercontent.com'
            )
          },
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
