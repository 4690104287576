export const environment = {
  production: true,
  INTERNATIONALIZATION_URL:
    "https://s3-eu-west-1.amazonaws.com/crazycall-staging-internationalization/sso/",
  COOKIE_DOMAIN: ".crazy-calls.com",
  SSO_URL: "https://staging-sso.crazy-calls.com",
  CHANNELS_SSO_URL: "https://staging-sso.aws-app.be",
  CHANNELS_URL: "https://staging-app.aws-app.be",
  SIGN_IN_WITH_APPLE_CLIENT_ID: "app.channels.my",
  SHOPER_CONNECT_SSO_DOMAIN: "aws-whitelabel.ch",
  CANNOT_USE_APP_URL: "https://res.channels.app/cannot-use-app",
};
