import { Injectable } from '@angular/core';
import * as Amplitude from 'amplitude-js/amplitude';
import { AppConfig } from '../config/app-config';
import { Router } from "@angular/router";

@Injectable()
export class AmplitudeService {
  amplitude = Amplitude.getInstance();

  constructor(appConfig: AppConfig, private router: Router) {
    if (this.router.url.includes('otp')) {
      this.amplitude.init(appConfig.shoperAmplitudeApiKey);
    } else {
      this.amplitude.init(appConfig.amplitudeApiKey);
    }
  }

  logEvent(...event) {
    this.amplitude.logEvent(...event);
  }

  setUserProperties(userProperties) {
    this.amplitude.setUserProperties(userProperties);
  }

  setUserId(userId) {
    this.amplitude.setUserId(userId);
  }
}
