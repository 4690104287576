import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cc-channels-main',
  templateUrl: './channels-main.component.html',
  styleUrls: ['./channels-main.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ChannelsMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
