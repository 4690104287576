import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'cc-material-button',
  templateUrl: './material-button.component.html',
  styleUrls: ['./material-button.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class MaterialButtonComponent implements OnInit {
  @Input() textKey: string;
  @Input() color: ThemePalette
  @Input() disabled = false;

  constructor() { }

  ngOnInit() {
  }

}
