import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AmplitudeService } from '../../utils/amplitude.service';

@Component({
  selector: 'cc-channels-register-switch',
  template: '<div></div>',
  encapsulation: ViewEncapsulation.Emulated
})
export class ChannelsRegisterSwitchComponent implements OnInit {
  private readonly registerRouteCookieName = 'registerRoute';
  private readonly possibleRoutes = ['register', 'new-account'];

  constructor(private router: Router,
              private cookieService: CookieService,
              private amplitudeService: AmplitudeService) {
  }

  ngOnInit() {
    const route = this.cookieService.get(this.registerRouteCookieName);

    if (this.possibleRoutes.includes(route)) {
      this.logAmplitude('Saved route detected. Redirecting', { route });
      this.changeRoute(route);
    } else {
      const route = this.chooseRandomRoute();
      this.logAmplitude('Redirecting to route', { route });
      this.cookieService.set(this.registerRouteCookieName, route);
      this.changeRoute(route);
    }
  }

  private changeRoute(route: string) {
    this.router.navigate([route], { queryParamsHandling: 'merge' });
  }

  private logAmplitude(message: string, event) {
    this.amplitudeService.logEvent(`Panel:Register Switch: ${message}`, event);
  }

  private chooseRandomRoute(): string {
    return this.possibleRoutes[Math.floor(Math.random() * this.possibleRoutes.length)];
  }

}
