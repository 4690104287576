<main class="main-wrapper">
  <cc-channels-the-best></cc-channels-the-best>
  <div class="right-background"></div>

  <cc-registration-form *ngIf="!afterSocial"
                        (onSubmit)="onSubmitForm($event)"
                        (socialClicked)="onSocialClicked($event)"
                        [inputsToShow]="inputsToShowInitially"
                        [buttonDisabled]="loading"
  ></cc-registration-form>

  <cc-registration-form *ngIf="afterSocial"
                        (onSubmit)="onSubmitForm($event)"
                        (socialClicked)="onSocialClicked($event)"
                        [allItemsInSeparateLine]="true"
                        [socialButtonsVisible]="false"
                        [inputsToShow]="inputsToShowAfterSocial"
                        [passwordRequired]="false"
                        [buttonDisabled]="loading"
                        titleKey="CHANNELS.REGISTRATION.SOCIAL_HEADER"
  ></cc-registration-form>

</main>
