import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';


export function ValidateDomain(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return {domain: true};
    }
    // return getDomain(control.value)
    //   ? null
    //   : {domain: true};
  }
}
