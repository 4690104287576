import { Component, ElementRef, forwardRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { AsYouType, CountryCode, getCountryCallingCode } from 'libphonenumber-js/max';

import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from '@angular/forms';
import { IpUserInfoService } from '../ip-user-info.service';
import * as sort from 'fast-sort';
import * as countryData from 'country-data';
import { isValidPhoneNumber } from '../validate-number';

const UP = 38;
const DOWN = 40;
const ENTER = 13;

@Component({
  selector: 'cc-intl-phone-number-input',
  templateUrl: './intl-phone-number-input.component.html',
  styleUrls: ['./intl-phone-number-input.component.scss'],
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => IntlPhoneNumberInputComponent), multi: true },
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => IntlPhoneNumberInputComponent), multi: true }
  ]
})
export class IntlPhoneNumberInputComponent implements OnInit, ControlValueAccessor, Validator {
  public phoneNumberCountryCode: string;
  public lastFormattedPhoneNumber = '';
  public phoneNumberInputClass: string;
  public phoneNumberInputValue = '';
  public selectedPhoneNumberIndex: number;
  public countries: any;
  public isOpenDropDown = false;

  private phoneNumberValidationRegex = /^\+?\d+$/;
  private countryData = countryData;
  private formatter: any = new AsYouType();
  private notServedCountry: any = ['XK', 'AC', 'PN', 'AQ', 'UM', 'TA', 'EU'];
  private search: { countryCode: string; countryIndex: number } = {} as any;

  @ViewChild('dropdown') dropdown: ElementRef;
  @ViewChild('phoneNumberInputElement') phoneNumberInputElement: ElementRef;
  @Input() isDisabled: Boolean = false;

  constructor(private IpUserInfoService: IpUserInfoService) {}

  ngOnInit() {
    this.IpUserInfoService.isReady().then(() => {
      if (!this.phoneNumberCountryCode) {
        this.setCountryCode(this.IpUserInfoService.getCountry().toLowerCase());
      } else {
        this.setSelectedPhoneNumberIndex(this.phoneNumberCountryCode.toUpperCase());
      }
    });
    this.countries = this.countryData.callingCountries.all.filter(
      country => !this.notServedCountry.includes(country.alpha2)
    );
    this.countries = sort(this.countries).asc((country) => country.name);

  }

  public onTouched = (_: any) => {};
  private propagateChange = (_: any) => {};

  onChange(phoneNumber: string, country: string = null) {
    phoneNumber = this.checkStartZero(phoneNumber);

    this.formatter.reset();
    this.formatter.input(phoneNumber as string);

    // this.setCountryCode(country || this.formatter.country || this.formatter.metadata._country);
    this.setCountryCode(country || this.formatter.country);

    this.lastFormattedPhoneNumber = this.getFormattedPhone();
    this.phoneNumberInputValue = phoneNumber;

    this.propagateChange(this.lastFormattedPhoneNumber);
  }

  dropDown(event: Event) {
    event.stopPropagation();

    this.isOpenDropDown = !this.isOpenDropDown;
    if (this.isOpenDropDown) {
      this.goToElementInList(this.selectedPhoneNumberIndex);
    }
  }

  onSelectCountry(country: any, index) {
    this.setCountryCode(country.alpha2);
    this.onChange(this.getFormattedPhone(), country.alpha2);
    this.selectedPhoneNumberIndex = this.search.countryIndex = index;
  }

  setCountryCode(countryCode: string) {
    this.phoneNumberCountryCode = countryCode ? countryCode.toLowerCase() : this.phoneNumberCountryCode;
    this.setSelectedPhoneNumberIndex(countryCode);
  }

  isValidNumber(phoneNumber): boolean {
    return (
      phoneNumber &&
      this.phoneNumberCountryCode &&
      this.phoneNumberValidationRegex.test(this.phoneNumberInputValue as string) &&
      isValidPhoneNumber(phoneNumber)
    );
  }

  getFormattedPhone(): string {
    const phoneCountryCode = getCountryCallingCode(<CountryCode>this.phoneNumberCountryCode.toUpperCase());
    const number = this.formatter.getNationalNumber();
    return number ? `+${phoneCountryCode}${number}` : '';
  }

  isActiveItem(countryCode: string): boolean {
    return countryCode.toLowerCase() === this.phoneNumberCountryCode;
  }

  isSearchItem(countryCode: string): boolean {
    return this.search.countryCode === countryCode;
  }

  validate(c: AbstractControl): ValidationErrors | any {
    if (!this.phoneNumberInputValue || this.isValidNumber(c.value)) {
      return null;
    }

    return { phone: true };
  }

  setSelectedPhoneNumberIndex(countryCode: string) {
    if (countryCode && this.phoneNumberCountryCode.toUpperCase() === countryCode.toUpperCase()) {
      this.selectedPhoneNumberIndex = this.search.countryIndex = this.countries.indexOf(
        this.countries.find(country => country.alpha2.toLowerCase() === this.phoneNumberCountryCode)
      );
    }
  }

  checkStartZero(phoneNumber) {
    if (phoneNumber && phoneNumber.startsWith('00')) {
      phoneNumber = `+${phoneNumber.slice(2)}`;
    }
    return phoneNumber;
  }

  dropDownUseKey(event) {
    if (event.keyCode >= 65 && event.keyCode <= 90) {
      const foundCountry = this.countries.find(country => country.name.startsWith(event.key.toUpperCase()));
      if (foundCountry) {
        this.search.countryCode = foundCountry.alpha2;
        this.search.countryIndex = this.countries.indexOf(
          this.countries.find(country => country.alpha2 === this.search.countryCode)
        );
        this.goToElementInList(this.search.countryIndex);
      }
    }
    if (event.keyCode === UP && this.search.countryIndex - 1 > 0) {
      this.search.countryIndex--;
      this.search.countryCode = this.countries[this.search.countryIndex].alpha2;
      this.goToElementInList(this.search.countryIndex);
    }

    if (event.keyCode === DOWN && this.search.countryIndex + 1 < this.countries.length) {
      this.search.countryIndex++;
      this.search.countryCode = this.countries[this.search.countryIndex].alpha2;
      this.goToElementInList(this.search.countryIndex);
    }

    if (event.keyCode === ENTER) {
      this.isOpenDropDown = false;
      this.selectedPhoneNumberIndex = this.search.countryIndex;
      this.onSelectCountry(this.countries[this.search.countryIndex], this.search.countryIndex);
    }
  }

  goToElementInList(index: number) {
    // get height child element in the dropdown
    const heightDropDownItem = this.dropdown.nativeElement.children[0].clientHeight;
    this.dropdown.nativeElement.scrollTop = index * heightDropDownItem + 3;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.onChange(obj);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}

  @HostListener('document:click', ['$event'])
  clickOutside(event) {
    this.isOpenDropDown = false;
  }

  preventClickPropagationOutside(event) {
    // part of clickOutside behaviour
    event.stopPropagation();
  }
}
