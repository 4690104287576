<div class="content">

  <p>Aby się zalogować podaj numer telefonu użyty do stworzenia Twojego konta</p>

  <form [formGroup]="phoneNumberGroup" class="form" (submit)="onSubmit()">

    <div class="input-error-group">
      <div data-prefix="+48" class="controls__element controls__element_prefix">
        <input #input
               maxlength="9"
               class="control input"
               [class.control_error]="errorCode"
               formControlName="phoneNumber"
               AllowOnly="phoneNumber"
        >
      </div>
      <div class="controls__errors ml-1" [class.hidden]="!errorCode">
        <span>Konto dla podanego numeru nie istnieje.</span>
      </div>
    </div>

    <button [disabled]="loading" class="btn btn_bg">Gotowe</button>

  </form>
</div>
