import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { accountsSdk } from '@livechat/accounts-sdk';
// import { NgxSwiperConfig } from "ngx-image-swiper";
import { Router } from "@angular/router";
import { AppConfig } from "../config/app-config";
import { environment } from "../../environments/environment";
import { EmailService } from "../shared/validators/email/email.service";

@Component({
  selector: 'cc-livechat',
  templateUrl: './livechat.component.html',
  styleUrls: ['./livechat.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class LivechatComponent implements OnInit {

  private token;
  private license;
  public showView;

  constructor(
    private router: Router,
    private appConfig: AppConfig,
    private emailService: EmailService
  ) {
  }

  ngOnInit() {
    accountsSdk.init({
      client_id: this.appConfig.livechatClientId,
      onIdentityFetched: (error, data) => {
        if (data) {
          this.token = data.access_token;
          this.license = data.license;
          this.emailService.checkEmailNotExists(data.entity_id).subscribe(exists => {
            this.showView = !exists;
            if (exists) {
              window.location.href = environment.CHANNELS_URL;
            }
          });
        }
      }
    });
  }

  // swiperConfig: NgxSwiperConfig = {
  //   navigation: false,
  //   pagination: true,
  //   border: false,
  //   imgBackgroundSize: '640px 360px'
  // };

  images = [
    '/assets/img/channels/livechat/slide1.png',
    '/assets/img/channels/livechat/slide2.png',
    '/assets/img/channels/livechat/slide3.png',
    '/assets/img/channels/livechat/slide4.png'
  ];

  startUsingChannels() {
    if (this.token) {
      window.location.href = `${environment.CHANNELS_URL}/integrations/init/livechat`
        + `?licenseID=${this.license}`
        + `&token=${this.token}`;
    }
  }

  connectWithLiveChat() {
    window.location.href = `${environment.CHANNELS_URL}/integrations/livechat`;
  }

  loginIfUserExists(email: string) {
    this.emailService.checkEmailNotExists(email).subscribe(exists => {
      if (exists) {
        this.startUsingChannels();
      }
    });
  }

}
