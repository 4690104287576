import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';

const linkCodeCookie: string = 'linkCode';
const extendedTrialCookie: string = 'extendedTrial';
@Injectable()
export default class AffiliatesParamsService {

  constructor(private cookieService: CookieService) {

  }
   addAffiliatesCookieParams(user) {

    user.linkCode = this.cookieService.get(linkCodeCookie)|| null ;
    user.extendedTrial =this.cookieService.get(extendedTrialCookie) || false;
  }
}
