export const isSpecialKey = (e: KeyboardEvent) =>
  [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
  // Allow: Ctrl+A
  (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
  // Allow: Ctrl+C
  (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
  // Allow: Ctrl+V
  (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
  // Allow: Ctrl+X
  (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
  // Allow: home, end, left, right
  (e.keyCode >= 35 && e.keyCode <= 39);
