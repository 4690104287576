import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cc-empty',
  template: '',
})
export class EmptyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
