import { Injectable } from '@angular/core';
import { REGISTER_TYPE, SocialRegisterData } from '../register/register-social/register-social.type';
import { BasicUser } from '../register/model/auth.model';
import { RegisterService } from '../shared/register.service';

@Injectable({
  providedIn: 'root'
})
export class AppleOauthService {

  constructor(private registerService: RegisterService) {
  }

  public async authorize(): Promise<{ user: BasicUser, socialData: SocialRegisterData }> {
    const data = await AppleID.auth.signIn();
    const { user, authorization } = data;
    const appleJwtToken = authorization.id_token;
    const socialData: SocialRegisterData = this.getAppleSocialRegisterData(appleJwtToken);

    /*
    Apple only returns the user object the first time the user authorizes the app.
    Subsequent authorization requests won’t contain the user object.
    https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
    */
    if (user) {
      const { email, name } = user;
      const { firstName, lastName } = name;
      return { user: { email, firstName, lastName }, socialData };
    } else {
      const tokenResponse = await this.registerService.getUserFromToken(appleJwtToken, 'APPLE').toPromise();
      if (tokenResponse.redirectUrl) {
        window.location.href = tokenResponse.redirectUrl;
      }
      return { user: { email: tokenResponse.email }, socialData };
    }
  }

  private getAppleSocialRegisterData(appleJwtToken: string): SocialRegisterData {
    const redirectUrl = new URL(window.location.href);
    redirectUrl.pathname = '/mobile/email-from-token';
    redirectUrl.searchParams.append('signInAuthority', 'APPLE');
    redirectUrl.searchParams.append('token', appleJwtToken);
    redirectUrl.searchParams.append('redirectImmediately', 'true');

    return { ...REGISTER_TYPE.APPLE, url: redirectUrl.toString() };
  }
}
