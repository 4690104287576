import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { EmailService } from './email.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

export function ValidateUniqueEmail(EmailService: EmailService): AsyncValidatorFn {
  return (control: AbstractControl): Observable<{ [key: string]: any }> => {
    return EmailService.checkEmailNotExists(control.value).pipe(
      map(exists =>
        !exists
          ? null
          : { unique: true })
    )
  }
}
