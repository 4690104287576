import {AppConfig} from '../../config/app-config';
import {SocialAuthServiceConfig , FacebookLoginProvider, GoogleLoginProvider,} from '@abacritt/angularx-social-login';

export function provideSocialAuthService(appConfig: AppConfig) {
  let providers = [
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(appConfig.googleApiKey)
    },
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider(appConfig.facebookAppId)
    }
  ];
  return {
    autoLogin: false,
    providers: providers,
  } as SocialAuthServiceConfig;
}
