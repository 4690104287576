<h3 class="welcome-title" translate>CHANNELS.WELCOME.TITLE</h3>
<p class="welcome-description" translate>CHANNELS.WELCOME.CONTENT</p>

<cc-social-buttons-group
  [providers]="socialProviders"
  (providerClick)="onSocialClick($event)"
></cc-social-buttons-group>

<div class="login-alert"
     role="alert"
     *ngIf="error">
  <i class="material-icons text-danger">cancel</i>
  <span class="text-danger ml-2"
        translate>{{error}}</span>
</div>
<form [formGroup]="registerForm"
      class="form-wrapper"
      novalidate>
  <div class="channels-form-group">
    <input formControlName="emailChannels"
           [placeholder]="'REGISTER.EMAIL' | translate"
           (keydown.enter)="continueWithEmail()"
           class="form-control"
           id="emailChannels"
           data-hj-whitelist
           autofocus>
    <cc-input-errors [form]="registerForm"
                     inputName="emailChannels"
                     class="error-wrapper"
                     [errors]="[{'required':'ERROR.REQUIRED'},
                                {'pattern': 'ERROR.EMAIL'},
                                {'domain': 'ERROR.EMAIL'},
                                {'empty': 'ERROR.NON_SPACE'}]"></cc-input-errors>
  </div>
  <button
    class="channels-button"
    (click)="continueWithEmail()"
    [innerHtml]="'CHANNELS.WELCOME.CONTINUE_WITH_EMAIL' | translate">
  </button>
</form>
