import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class ResetService {

  constructor(private http: HttpClient) {}

  email(email): Observable<any> {
    return this.http
      .post('/password/reset', { email })
  }

  verifyToken(token): Observable<any> {
    return this.http
      .get('/password/reset?token=' + token)
  }

  changePassword(password, token) {
    return this.http
      .put('/password/reset', {password: password, token: token})
  }

}
