<section class="section-wrapper">
  <h1 translate class="header">{{titleKey}}</h1>


  <form [formGroup]="registrationForm"
        class="form"
        (submit)="submitForm()">

    <div class="inputs-group resized-inputs">
      <div class="one-line-wrapper" [class.separate-line]="allItemsInSeparateLine">
        <cc-material-input
          *ngIf="inputsToShow.has('name')"
          [parentForm]="registrationForm"
          controlName="name"
          appearance="outline"
          [required]="true"
          labelKey="REGISTER.NAME"
        ></cc-material-input>

        <cc-material-input
          *ngIf="inputsToShow.has('surname')"
          [parentForm]="registrationForm"
          controlName="surname"
          appearance="outline"
          [required]="true"
          labelKey="REGISTER.SURNAME"
        ></cc-material-input>
      </div>

      <div class="one-line-wrapper" [class.separate-line]="allItemsInSeparateLine">
        <cc-material-input
          *ngIf="inputsToShow.has('company')"
          [parentForm]="registrationForm"
          controlName="company"
          appearance="outline"
          [required]="true"
          labelKey="REGISTER.ACCOUNT_NAME"
        ></cc-material-input>


        <cc-material-input
          *ngIf="inputsToShow.has('phone')"
          [parentForm]="registrationForm"
          controlName="phone"
          appearance="outline"
          labelKey="REGISTER.PHONE"
          allowOnly="phoneNumber"
          [errors]="[{key: 'pattern', messageKey: 'ERROR.PHONE'}]"
        ></cc-material-input>
      </div>

      <cc-material-input
        *ngIf="inputsToShow.has('email')"
        [parentForm]="registrationForm"
        controlName="email"
        appearance="outline"
        type="email"
        [required]="true"
        labelKey="REGISTER.EMAIL"
        [errors]="[{key: 'email', messageKey: 'ERROR.EMAIL'}]"
      ></cc-material-input>

      <cc-material-input
        *ngIf="inputsToShow.has('password')"
        [parentForm]="registrationForm"
        controlName="password"
        appearance="outline"
        type="password"
        [required]="passwordRequired"
        labelKey="PASSWORD"
        [errors]="[{key: 'minlength', messageKey: 'ERROR.PASSWORD'}]"
      ></cc-material-input>

      <cc-material-input
        *ngIf="inputsToShow.has('website')"
        [parentForm]="registrationForm"
        controlName="website"
        appearance="outline"
        labelKey="REGISTER.WEBSITE"
      ></cc-material-input>
    </div>

    <cc-material-button
      textKey="CHANNELS.REGISTRATION.BUTTON"
      color="accent"
      [disabled]="buttonDisabled"
    ></cc-material-button>
  </form>

  <ng-container *ngIf="socialButtonsVisible">
    <div class="or-sign-in-wrapper">
      <div class="line"></div>
      <span class="or-sign-in" translate>CHANNELS.REGISTRATION.OR_SIGN_IN</span>
    </div>

    <div class="social-wrapper">
      <cc-social-button *ngFor="let social of socialProviders"
                        [text]="social.text"
                        [provider]="social.provider"
                        (socialClicked)="socialClicked.emit($event)"
      >
      </cc-social-button>
    </div>
  </ng-container>


  <div class="login-wrapper">
    <span translate>CHANNELS.REGISTRATION.HAVE_ACCOUNT</span>
    <a routerLink="/login" (click)="loginClicked()">{{'CHANNELS.REGISTRATION.SIGN_IN_NOW' | translate}}</a>
  </div>

  <div class="privacy">
    <span translate>POLICY.CREATING</span>
    <a href="https://www.channels.app/terms-conditions" target="_blank" translate>POLICY.TERMS</a>
    <span translate>POLICY.AND</span>
    <a href="https://www.channels.app/privacy-policy" target="_blank" translate>POLICY.PRIVACY</a>
  </div>

</section>
