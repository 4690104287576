import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AmplitudeService } from '../../utils/amplitude.service';
import { RegisterService } from '../../shared/register.service';

@Component({
  selector: 'cc-account-verify',
  templateUrl: './account-verify.component.html',
  styleUrls: ['./account-verify.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AccountVerifyComponent implements OnInit {

  constructor(private amplitudeService: AmplitudeService, private registerService: RegisterService) { }

  ngOnInit() {
    this.amplitudeService.logEvent('SSO:Registration:Final: Verification');
    this.registerService.trackEvent('sso-registration-verification');
  }

}
