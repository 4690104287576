import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cc-functions-description',
  templateUrl: './functions-description.component.html',
  styleUrls: ['./functions-description.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class FunctionsDescriptionComponent implements OnInit {

  @Input() text: string;

  constructor() { }

  ngOnInit() {
  }

}
