import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { first } from 'rxjs/operators';

@Injectable()
export class UtmService {

  private static readonly SESSION_LENGTH = 60 * 12;
  private static readonly PATH = '/';
  private static readonly REFERRER = 'referrer';
  private static readonly PARAMS = {
    utmSource: 'utm_source',
    utmMedium: 'utm_medium',
    utmCampaign: 'utm_campaign',
    utmContent: 'utm_content',
    utmTerm: 'utm_term'
  };

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private cookieService: CookieService) {
  }

  private static getExpirationDate(): Date {
    const date = new Date();
    date.setMinutes(date.getMinutes() + UtmService.SESSION_LENGTH);
    return date;
  }

  saveParams() {
    const expirationDate = UtmService.getExpirationDate();
    if (this.hasPreviousReferrer() && this.isCurrentReferrerCrazyCall()) {
      this.refreshUtms(expirationDate);
      this.refreshReferrer(expirationDate);
    } else {
      this.saveUtms(expirationDate);
      this.saveReferrer(expirationDate);
    }
  }

  appendParamsToUser(user: any) {
    this.appendUtms(user);
    this.appendReferrer(user);
  }

  private isCurrentReferrerCrazyCall(): boolean {
    const referrerHostname = !!document.referrer ? new URL(document.referrer).hostname : '';
    return referrerHostname.endsWith(environment.COOKIE_DOMAIN);
  }

  private hasPreviousReferrer() {
    return this.cookieService.check(UtmService.REFERRER);
  }

  private saveUtms(expirationDate: Date) {
    this.router.events.pipe(
      first(event => event instanceof NavigationEnd)
    ).subscribe(_ => {
      const queryParams = this.activatedRoute.snapshot.queryParams;
      Object.entries(UtmService.PARAMS).forEach(paramEntry => {
        const [name, value] = paramEntry;
        this.saveCookie(value, queryParams[value], expirationDate);
      });
    });
  }

  private saveReferrer(expirationDate: Date) {
    this.saveCookie(UtmService.REFERRER, document.referrer, expirationDate);
  }

  private saveCookie(key: string, value: string, expirationDate: Date) {
    this.cookieService.set(key, !!value ? value : '', expirationDate, UtmService.PATH, environment.COOKIE_DOMAIN);
  }

  private refreshUtms(expirationDate: Date) {
    Object.entries(UtmService.PARAMS).forEach(paramEntry => {
      const [name, value] = paramEntry;
      this.refreshCookie(value, expirationDate);
    });
  }

  private refreshReferrer(expirationDate: Date) {
    this.refreshCookie(UtmService.REFERRER, expirationDate);
  }

  private refreshCookie(key: string, expirationDate: Date) {
    const existingValue = this.cookieService.get(key);
    if (existingValue !== undefined) {
      this.saveCookie(key, existingValue, expirationDate);
    }
  }

  private appendUtms(user: any) {
    Object.entries(UtmService.PARAMS).forEach(paramEntry => this.appendUtm(user, paramEntry));
  }

  private appendUtm(user: any, paramEntry: string[]) {
    const [key, value] = paramEntry;
    const existingValue = this.cookieService.get(value);
    if (existingValue) {
      user[key] = existingValue;
    }
  }

  private appendReferrer(user: any) {
    const existingValue = this.cookieService.get(UtmService.REFERRER);
    if (existingValue) {
      user[UtmService.REFERRER] = existingValue;
    }
  }

}
