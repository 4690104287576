import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RegisterService } from '../../shared/register.service';
import { LoginService } from '../../login/login.service';
import { AppConfig } from '../../config/app-config';
import { AmplitudeService } from '../../utils/amplitude.service';
import { findInvalidControls } from '../../shared/reactive-forms/findInvalidControls';
import { localStorageSupported } from '../../shared/utils/localStorageUtils';

@Component({
  selector: 'cc-channels-login',
  templateUrl: './channels-login.component.html',
  styleUrls: ['./channels-login.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ChannelsLoginComponent implements OnInit {
  public error: string;
  public hide = true;

  constructor(private fb: UntypedFormBuilder,
              private registerService: RegisterService,
              private loginService: LoginService,
              private appConfig: AppConfig,
              private amplitudeService: AmplitudeService,
              private route: Router) {
  }

  public loginForm: UntypedFormGroup;
  public email: string;

  ngOnInit() {
    const queryError = this.route.routerState.snapshot.root.queryParams.error;
    this.email = this.isUserCompleteSoFar();

    if (localStorageSupported()) {
      this.email = this.isUserCompleteSoFar() || localStorage.getItem('email');
      localStorage.setItem('email', this.email);
    }
    if (queryError) {
      this.amplitudeService.logEvent('SSO:Login:Password: Error', { queryError });
      this.error = `LOGIN.ERROR.${queryError.toUpperCase()}`;
    } else {
      this.amplitudeService.logEvent('SSO:Login:Password: Init');
    }

    this.createForm();
    if (!this.email) {
      this.route.navigate(['/login']);
    }
  }

  isUserCompleteSoFar() {
    return this.registerService.getEmail();
  }

  createForm() {
    this.loginForm = this.fb.group(({
      email: [this.email,
        Validators.compose([Validators.required, Validators.pattern(this.appConfig.emailPattern)])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6)])]
    }));
  }

  login() {
    if (this.loginForm.valid) {
      this.amplitudeService.logEvent('SSO:Login:Password: Submit');
      this.registerService.trackEvent('sso-login-email');
      this.registerService.updateUser(this.loginForm.value);
      this.loginService.loginUser();
    } else {
      this.amplitudeService.logEvent('SSO:Login:Password: Invalid form', findInvalidControls(this.loginForm));
    }
  }
}
