import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationHeaderComponent } from './information-header/information-header.component';
import { IntlPhoneNumberInputComponent } from './intl-phone-number-input/intl-phone-number-input.component';
import { EmptyComponent } from './empty/empty.component';
import { GtmService } from './gtm.service';
import { IpUserInfoService } from './ip-user-info.service';
import { InputErrorsComponent } from './input-errors/input-errors.component';
import { InputErrorComponent } from './input-errors/input-error/input-error.component';

import { LiveChatService } from './live-chat.service';
import { AmplitudeService } from './amplitude.service';
import { UtmService } from './utm.service';
import { CookieService } from 'ngx-cookie-service';
import { VerifyGuard } from './guards/verify.guard';
import { TranslateModule } from '@ngx-translate/core';
import { SentryService } from './sentry.service';
import { LiveChatOauthService } from './live-chat.oauth.service';
import { CouponService } from './coupon.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [
    InformationHeaderComponent,
    IntlPhoneNumberInputComponent,
    EmptyComponent,
    InputErrorComponent,
    InputErrorsComponent
  ],
  providers: [
    GtmService,
    IpUserInfoService,
    LiveChatService,
    LiveChatOauthService,
    AmplitudeService,
    UtmService,
    CookieService,
    VerifyGuard,
    SentryService,
    CouponService
  ],
  exports: [
    InformationHeaderComponent,
    InputErrorComponent,
    InputErrorsComponent,
    IntlPhoneNumberInputComponent
  ]
})
export class UtilsModule {
}
