import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class VerifyPhoneNumberService {

  constructor(private http: HttpClient) { }

  sendCode(params) {
    return this.http.post('/msisdn/verify/send-code', params)
  }

}
