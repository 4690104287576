import {
  AfterViewInit,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output, ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: "shoper-connect-otp-verification-code",
  templateUrl: "./otp-verification-code.component.html",
  styleUrls: ["./otp-verification-code.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class OtpVerificationCodeComponent implements OnInit, AfterViewInit {
  @Input() phoneNumber: string;
  @Input() errorCode = false;
  @Input() loading = false;

  verificationGroup: UntypedFormGroup;
  @Output() submit = new EventEmitter<{ code: string }>();
  @Output() goBack = new EventEmitter();
  @Output() reSend = new EventEmitter();
  @ViewChild('input') input: ElementRef;

  tokenExtinct = false;
  tenMinutes = 600000;



  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.verificationGroup = this.fb.group({
      code: ["", Validators.required],
    });
    this.codeControl.valueChanges.subscribe(value => {
      this.errorCode = false;
    })
    setTimeout(() => {
      this.tokenExtinct = true;
    } ,this.tenMinutes);
  }
  ngAfterViewInit(): void {
    this.input.nativeElement.focus();
  }

  get maskedPhoneNumber() {
    return "*** *** " + this.phoneNumber.slice(this.phoneNumber.length - 3);
  }

  get codeControl() {
    return this.verificationGroup.get('code');
  }

  onSubmit() {
    this.errorCode = false;
    const { code } = this.verificationGroup.getRawValue();
    this.codeControl.markAsDirty();
    this.submit.emit({ code });
  }

  sendSmsAgain() {
    this.errorCode = false;
    this.tokenExtinct = false;
    this.reSend.emit();
    this.verificationGroup.reset();
    setTimeout(() => {
      this.tokenExtinct = true;
    } ,this.tenMinutes);
  }
}
