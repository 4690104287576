import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {RegisterService} from "../../shared/register.service";
import {LanguageService} from "../../shared/services/language.service";
import {Router} from "@angular/router";

@Component({
  selector: 'cc-channels-register',
  templateUrl: './channels-register.component.html',
  styleUrls: ['./channels-register.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ChannelsRegisterComponent implements OnInit {
  public registerForm: UntypedFormGroup;
  public hide = true;

  constructor(public registerService: RegisterService,
              public router: Router,
              public languageService: LanguageService
  ) {}

  ngOnInit() {}
}
