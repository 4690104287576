import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { isSpecialKey } from "./utils/keyboardUtils";

@Directive({
  selector: "[AllowOnly]",
})
export class AllowOnlyDirective {
  constructor(private el: ElementRef) {}

  @Input() AllowOnly?: AllowOnly = null;

  @HostListener("keydown", ["$event"]) onKeyDown(e: KeyboardEvent) {
    if (!this.AllowOnly) {
      return;
    }

    const isPlusSignForPhoneNumbers =
      this.AllowOnly === "phoneNumber" &&
      (e.keyCode === 187 || e.keyCode === 43 || e.keyCode === 61);

    if (isSpecialKey(e) || isPlusSignForPhoneNumbers) {
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }
}

export type AllowOnly = "numeric" | "phoneNumber";
