<ng-container  *ngFor="let social of providers">
  <cc-social-button
    *ngIf="social.provider !== 'GOOGLE'"
    [provider]="social.provider"
    [text]="social.text"
    (socialClicked)="providerClick.emit(social.provider)"
  ></cc-social-button>


</ng-container>
<div *ngIf="socialButtonWidth">
  <asl-google-signin-button

    type="standard"
    shape="pill"
    theme="outline"
    text="signin_with"
    size="large"
    logo_alignment="left"
    [width]="getSocialButtonWidth()">
  </asl-google-signin-button>
</div>
<div class="separation-line-wrapper">
  <div class="separation-line">
    <hr>
  </div>
  <div class="separation-txt">
    {{'CHANNELS.WELCOME.OR' | translate}}
  </div>
</div>
