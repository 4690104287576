import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {markFormAsTouched} from '../reactive-forms/markFormAsTouched';

@Directive({
  selector: '[ccScrollToFirstInvalidInput]'
})
export class ScrollToFirstInvalidInputDirective {
  @Input() private formGroup: UntypedFormGroup;

  constructor(private el: ElementRef) {}

  @HostListener('submit')
  onFormSubmit() {
    const invalidElements = this.el.nativeElement.querySelectorAll('input.ng-invalid,mat-radio-group.ng-invalid');
    if (invalidElements.length > 0) {
      const el = ScrollToFirstInvalidInputDirective.findTheMostTopElement(invalidElements);

      markFormAsTouched(this.formGroup);

      el.focus();
      el.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
    }
  }

  private static findTheMostTopElement(elements: NodeList): HTMLElement {
    const array = Array.from(elements) as [HTMLElement];

    array.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top);

    return array[0];
  }
}
