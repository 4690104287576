import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

interface IExistInstance {
  exists: boolean;
}
@Injectable()
export class AccountService {
  constructor(private http: HttpClient) {}

  checkAccountNotExists(account): Observable<boolean> {
    return this.http
      .get<IExistInstance>(`/instance/exists?name=${account}`).pipe(
        map(res => res.exists)
      );
  }
}
