import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoginService } from '../../login/login.service';
import { RegisterService } from '../../shared/register.service';
import { SocialAuthService , GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { Router } from '@angular/router';
import { RegisterHelperService } from '../../register/register-helper.service';
import { LanguageService } from '../../shared/services/language.service';
import { REGISTER_TYPE, SocialRegisterProvider } from '../../register/register-social/register-social.type';
import { GoogleUserParseService } from '../../register/register-social/google-user-parse.service';
import { AmplitudeService } from '../../utils/amplitude.service';
import { LiveChatOauthService } from '../../utils/live-chat.oauth.service';
import { AppleOauthService } from '../../utils/apple.oauth.service';
import { SocialButtonProvider } from '../social-buttons-group/social-buttons-group.component';

@Component({
  selector: 'cc-channels-invite',
  templateUrl: './channels-invite.component.html',
  styleUrls: ['./channels-invite.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ChannelsInviteComponent implements OnInit {
  private email: string;
  private name: string;
  private surname: string;
  private password: string;
  socialProviders: SocialButtonProvider[] = [
    { provider: 'GOOGLE', text: 'CHANNELS.WELCOME.CONTINUE_WITH_GOOGLE' },
    { provider: 'LIVECHAT', text: 'CHANNELS.WELCOME.CONTINUE_WITH_LIVECHAT' },
    { provider: 'APPLE', text: 'CHANNELS.WELCOME.CONTINUE_WITH_APPLE' }
  ];


  constructor(private authService: SocialAuthService ,
              private router: Router,
              private registerHelperService: RegisterHelperService,
              private registerService: RegisterService,
              private loginService: LoginService,
              private languageService: LanguageService,
              private amplitudeService: AmplitudeService,
              private liveChatOauthService: LiveChatOauthService,
              private appleOauthService: AppleOauthService) {
  }

  ngOnInit() {
    this.registerService.onRegisterWithFormData.subscribe(data => {
      this.setInvitedDetails(data.name, data.surname, data.password);
      this.registerService.updateUser({
        password: this.password,
        name: this.name,
        surname: this.surname,
        language: this.languageService.selectLanguageBasedOnBrowserSettings()
      });
      this.registerInvited();
    });
  }

  onSocialClick(provider: SocialRegisterProvider) {
    switch (provider) {
      case 'APPLE':
        return this.appleRegister();
      case 'GOOGLE':
        return this.googleRegister();
      case 'LIVECHAT':
        return this.livechatRegister();
      default:
        throw new Error(`Unsupported social provider [${provider}]`);
    }
  }

  googleRegister() {
    this.amplitudeService.logEvent('SSO:Invite: Continue', { provider: 'GOOGLE' });
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
      this.updateRegisterType(REGISTER_TYPE.GOOGLE);
      GoogleUserParseService.parseFromGoogleUser(user);
      this.setDetails(user.email, user.firstName, user.lastName);
      this.updateUser();
      this.registerInvited();
    }).catch(reason => console.warn(reason));
  }

  async livechatRegister() {
    this.amplitudeService.logEvent('SSO:Invite: Continue', { provider: 'LIVECHAT' });
    const user = await this.liveChatOauthService.login();
    this.updateRegisterType(REGISTER_TYPE.LIVECHAT);
    this.setDetails(user.email, user.firstName, user.lastName);
    this.updateUser();
    this.registerInvited();
  }

  async appleRegister() {
    this.amplitudeService.logEvent('SSO:Invite: Continue', { provider: 'APPLE' });

    const { user, socialData } = await this.appleOauthService.authorize();
    this.updateRegisterType(socialData);
    this.registerService.updateUser(user);
    this.setDetails(user.email, user.firstName, user.lastName);
    this.updateUser();
    this.registerInvited();
  }

  updateRegisterType(type) {
    this.registerService.setRegisterType(type);
  }

  setDetails(email, name, surname) {
    this.email = email;
    this.name = name;
    this.surname = surname;
  }

  setInvitedDetails(name, surname, password) {
    this.password = password;
    this.name = name;
    this.surname = surname;
  }

  updateUser() {
    this.registerService.updateUser({
      email: this.email,
      name: this.name,
      surname: this.surname,
      language: this.languageService.selectLanguageBasedOnBrowserSettings()
    });
  }

  registerInvited() {
    this.registerService.inviteSocialRegister(() => {
      this.registerService.trackEvent('sso-registration-invite');
      this.registerHelperService.socialLogin();
    });
  }
}
