import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

const forEachElementInForm = (group: UntypedFormGroup | UntypedFormArray, func: (FormControl) => any = () => {}) => {
  func(group);

  for (const i in group.controls) {
    const control = group.controls[i];

    if (control instanceof UntypedFormControl) {
      func(control);
    } else {
      forEachElementInForm(control, func);
    }
  }
};

export const markFormAsTouched = (group: UntypedFormGroup | UntypedFormArray) => {
  forEachElementInForm(group, control => control.markAsTouched());
};
