import { Injectable } from '@angular/core';
import { AppConfig } from '../config/app-config';

@Injectable()
export class LiveChatService {
  LIVE_CHAT_PATCH = "/assets/js/liveChat.js";

  constructor(private AppConfig: AppConfig) {
  }

  contactSupport() {
    window['LC_API'].open_chat_window();

  }

  initUserInfo() {
    window['__lc'] = {};
    window['__lc'].license = this.AppConfig.liveChatLicense;

    window['__lc'].params = [];
    window['__lc'].params.push({name: 'Domain', value: window.location.hostname});
    if (!this.isLocalhost()) this.loadScript(this.LIVE_CHAT_PATCH);
    if (this.isLiveChatIframe()) setTimeout(() => this.hideLiveChatWidget(), 250);
  }

  isLocalhost() {
    return window.location.hostname === 'localhost';
  }

  public loadScript(file) {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = file;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  private isLiveChatIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  private hideLiveChatWidget() {
    if (!window['LC_API']) {
      return;
    }

    window['LC_API'].on_after_load = function () {
      const chatWidget = window.document.getElementById("chat-widget-container");
      if (chatWidget) {
        chatWidget.style.visibility = "hidden";
      }
    }
  }

}
