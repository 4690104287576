<h3 class="channels-account-verify-title" translate>CHANNELS.REGISTER.ACCOUNT_VERIFY</h3>
<img class="channels-account-verify-icon" src="../../../../assets/img/channels/account-verify.svg" alt="account-verify">
<p class="channels-account-verify-description" translate>CHANNELS.REGISTER.ACCOUNT_VERIFY_CONTENT</p>

<a href="https://www.channels.app/" class="link-wrapper">
  <button class="channels-button"
          id="reset"
          translate>CHANNELS.REGISTER.GO_TO_HOMEPAGE
  </button>
</a>
