<div class="text-muted text-sm">
  <span translate>POLICY.CREATING</span>
  <a href="https://www.channels.app/terms-conditions/"
     target="_blank"
     translate
     class="text-muted">POLICY.TERMS</a>
  <span translate>POLICY.AND</span>
  <a href="https://www.channels.app/privacy-policy/"
     target="_blank"
     translate
     class="text-muted">POLICY.PRIVACY</a>
</div>
