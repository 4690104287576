import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {RegisterService} from '../../../shared/register.service';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class CompanyGuard implements CanActivate {
  constructor(private RegisterService: RegisterService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
     if (!this.RegisterService.hasDetails()) {
       this.router.navigate(['/register', 'details']);
       return false;
     }
     return true
  }
}
