import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { AlternativeUser } from '../shared/register.model';

@Injectable()
export class RegisterAlternativeRequestsService {

  constructor(private http: HttpClient) {}

  registrationChecking(params: AlternativeUser): Observable<AlternativeUser> {
    return this.http.post(`/registration`, params);
  }
}
