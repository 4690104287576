import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '../utils/utils.module';
import { ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { LoginService } from './login.service';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    UtilsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    SharedModule
  ],
  providers: [LoginService],
  declarations: [],
  exports: []

})
export class LoginModule { }
