import { Injectable } from '@angular/core';
import * as Raven from "raven-js";
import { AppConfig } from "../config/app-config";

@Injectable()
export class SentryService {

  constructor(private AppConfig: AppConfig) {
  }

  setInitialParams() {
    Raven.config('https://e9a060c6c3f54aa6b978a1444afee58b@sentry.io/250873', {
      release: this.AppConfig.commitHash,
      shouldSendCallback: () => this.AppConfig.sentryEnabled
    }).install();
  }
}
