import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RegisterService } from '../../shared/register.service';
import { LoginService } from '../../login/login.service';
import { Router } from '@angular/router';
import { RegisterHelperService } from '../../register/register-helper.service';
import { LanguageService } from '../../shared/services/language.service';
import { AmplitudeService } from 'app/utils/amplitude.service';

@Component({
  selector: 'cc-channels-details',
  templateUrl: './channels-details.component.html',
  styleUrls: ['./channels-details.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ChannelsDetailsComponent implements OnInit {
  public registerForm: UntypedFormGroup;
  public hide = true;
  @Input() invited: boolean;
  @Output() onDetailsClick = new EventEmitter();

  constructor(private fb: UntypedFormBuilder,
              public registerService: RegisterService,
              public loginService: LoginService,
              public router: Router,
              public registerHelperService: RegisterHelperService,
              public languageService: LanguageService,
              private amplitudeService: AmplitudeService) {
  }

  ngOnInit() {
    this.createForm();
    this.updateForm();
    this.amplitudeService.logEvent('SSO:Registration:Details: Init');
    if (this.invited || this.isUserCompleteSoFar()) {
      this.updateForm();
    } else {
      this.router.navigate(['/']);
    }
  }

  isUserCompleteSoFar() {
    return this.registerService.getEmail();
  }

  updateForm() {
    this.registerHelperService.updateForm(this.registerForm);
  }

  createForm() {
    this.registerForm = this.registerHelperService.createForm(['name', 'surname', 'password']);
  }

  checkValidation(form) {
    Object.keys(form.controls).forEach(field => {
      form.controls[field].markAsTouched();
    });
  }

  registerWithInvitedDetails() {
    this.checkValidation(this.registerForm);

    if (this.registerForm.valid) {
      this.amplitudeService.logEvent('SSO:Registration:Details: Register after invitation');
      this.registerService.updateUser(
        {
          name: this.registerForm.value.name,
          surname: this.registerForm.value.surname,
          password: this.registerForm.value.password
        }
      );

      this.registerService.inviteRegister(() => {
        this.registerService.trackEvent('sso-registration-invite');
        this.loginService.loginUser();
      });
    } else {
      this.amplitudeService.logEvent('SSO:Registration:Details: Register after invitation - invalid form');
    }
  }

  goToFinal() {
    this.checkValidation(this.registerForm);
    if (this.registerForm.valid) {
      this.amplitudeService.logEvent('SSO:Registration:Details: Register');
      this.registerService.updateUser(
        {
          name: this.registerForm.value.name,
          surname: this.registerForm.value.surname,
          password: this.registerForm.value.password
        }
      );
      this.updateForm();
      this.router.navigate(['/final-register', 'company']);
    } else {
      this.amplitudeService.logEvent('SSO:Registration:Details: Register - invalid form');
    }
  }
}
