import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {REGISTER_TYPE, SocialRegisterProvider} from '../../register/register-social/register-social.type';
import { RegisterService } from '../../shared/register.service';
import { Router } from '@angular/router';
import { AmplitudeService } from '../../utils/amplitude.service';
import { RegisterHelperService } from '../../register/register-helper.service';
import { FormGroup } from '@angular/forms';
import { EmailService } from '../../shared/validators/email/email.service';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import { localStorageSupported } from '../../shared/utils/localStorageUtils';
import { SocialButtonProvider } from '../social-buttons-group/social-buttons-group.component';
import { SocialRegisterHelperService } from '../../register/social-register-helper.service';
import {SocialAuthService} from "@abacritt/angularx-social-login";


@Component({
  selector: 'cc-channels-welcome',
  templateUrl: './channels-welcome.component.html',
  styleUrls: ['./channels-welcome.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ChannelsWelcomeComponent implements OnInit {
  public error: string;
  public registerForm: FormGroup;
  public userExist: boolean;
  private email: string;
  private name: string;
  private surname: string;
  progressRef: NgProgressRef;

  socialProviders: SocialButtonProvider[] = [
    { provider: 'GOOGLE', text: 'CHANNELS.WELCOME.CONTINUE_WITH_GOOGLE' },
    { provider: 'LIVECHAT', text: 'CHANNELS.WELCOME.CONTINUE_WITH_LIVECHAT' },
    { provider: 'APPLE', text: 'CHANNELS.WELCOME.CONTINUE_WITH_APPLE' }
  ];

  constructor(public route: Router,
              private registerService: RegisterService,
              private amplitudeService: AmplitudeService,
              private router: Router,
              private registerHelperService: RegisterHelperService,
              private emailService: EmailService,
              private socialRegistrationHelper: SocialRegisterHelperService,
              public ngProgress: NgProgress,
              private authService: SocialAuthService
  ) {
  }

  ngOnInit() {
    const queryError = this.route.routerState.snapshot.root.queryParams.error;
    if (localStorageSupported()) {
      localStorage.removeItem('email');
    }
    if (queryError) {
      this.error = `LOGIN.ERROR.${queryError.toUpperCase()}`;
    }
    this.progressRef = this.ngProgress.ref('myProgress');
    this.createForm(['emailChannels']);
    this.updateForm();
    this.amplitudeService.logEvent('SSO:Welcome: Init');
    this.authService.authState.subscribe((user) => {
      this.onSocialClick('GOOGLE', user);
    });
  }

  createForm(fields): void {
    this.registerForm = this.registerHelperService.createForm(fields);
  }

  updateForm() {
    this.registerHelperService.updateForm(this.registerForm);
  }

  goToLogin() {
    this.updateForm();
    this.router.navigate(['/login', 'welcome-channels']);
  }

  async onSocialClick(provider: SocialRegisterProvider, socialUser?) {
    this.amplitudeService.logEvent('SSO:Welcome: Continue', { provider });
    try {
      await this.socialRegistrationHelper.tryToRegisterWithSocialClient(provider, socialUser);
      await this.router.navigate(['/final-register', 'company']);
    } catch (error) {
      this.amplitudeService.logEvent('SSO:Welcome: Error', { provider, error });
      this.progressRef.complete();
      this.router.navigate(['register', 'error']);
    }
  }

  setDetails(email, name, surname) {
    this.email = email;
    this.name = name;
    this.surname = surname;
  }

  updateUser() {
    this.registerService.updateUser({
      email: this.email.trim(),
      name: this.name,
      surname: this.surname
    });
  }

  updateRegisterType(type) {
    this.registerService.setRegisterType(type);
  }

  continueWithEmail() {
    this.registerForm.get('emailChannels').markAsTouched({ onlySelf: true });
    if (this.registerForm.valid) {
      const email = this.registerForm.value.emailChannels.trim();
      this.amplitudeService.logEvent('SSO:Welcome: Continue', { provider: 'email' });
      this.progressRef.start();
      this.emailService.checkEmailNotExists(email).subscribe((exists) => {
        this.userExist = exists;
        if (this.userExist) {
          this.progressRef.complete();
          this.registerService.updateUser({ email, exists: true });
          this.goToLogin();
        } else {
          this.registerService.trackEvent('sso-step1-email');
          this.registerService.updateUser({ email, exists: false });
          this.progressRef.complete();
          this.router.navigate(['/register']);
        }
      });
    } else {
      this.amplitudeService.logEvent('SSO:Welcome: Invalid email');
    }
  }
}
