import {Injectable} from '@angular/core';
import {accountsSdk} from '@livechat/accounts-sdk';
import {AppConfig} from '../config/app-config';
import { SocialUser } from "@abacritt/angularx-social-login";

@Injectable()
export class LiveChatOauthService {

  constructor(private appConfig: AppConfig) {
  }

  login(): Promise<SocialUser> {
    return new Promise<SocialUser>((resolve, reject) => {
      const livechatSdk = accountsSdk.init({
        client_id: this.appConfig.livechatClientId,
        onIdentityFetched: (error, data) => {
          if (data) {
            const user = new SocialUser();
            user.email = data.entity_id;
            resolve(user);
          }
          if (error) {
            if (error.identity_exception === 'unauthorized') {
              livechatSdk.openPopup();
            } else {
              livechatSdk.signOut(function () {
              });
              reject(error);
            }
          }
        }
      });
    });
  }

}
