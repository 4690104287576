import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AmplitudeService } from '../../../utils/amplitude.service';
import { markFormAsTouched } from '../../../shared/reactive-forms/markFormAsTouched';
import { User } from '../../../shared/register.model';
import { SocialButtonProvider } from '../../social-buttons-group/social-buttons-group.component';
import { SocialRegisterProvider } from '../../../register/register-social/register-social.type';
import { isMobileSize } from '../../../shared/utils/deviceUtils';

@Component({
  selector: 'cc-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class RegistrationFormComponent implements OnInit {
  @Input() inputsToShow: Set<RegistrationFormInput> = new Set<RegistrationFormInput>();
  @Input() allItemsInSeparateLine = false;
  @Input() socialButtonsVisible = true;
  @Input() titleKey = 'CHANNELS.REGISTRATION.HEADER';
  @Input() passwordRequired = true;
  @Input() buttonDisabled = false;
  @Output() onSubmit = new EventEmitter<User>();
  @Output() socialClicked = new EventEmitter<SocialRegisterProvider>();

  registrationForm: UntypedFormGroup;
  isMobile = false;

  socialProviders: SocialButtonProvider[] = [
    { provider: 'GOOGLE', text: 'Google' },
    { provider: 'LIVECHAT', text: 'LiveChat' },
    { provider: 'APPLE', text: 'Apple' }
  ];


  constructor(private amplitudeService: AmplitudeService) {
  }

  ngOnInit() {
    this.registrationForm = this.createForm();
    this.isMobile = isMobileSize();
  }

  private createForm(): UntypedFormGroup {
    const allFormControls = {
      name: new UntypedFormControl('', [Validators.required]),
      surname: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', this.passwordRequired ? [Validators.required, Validators.minLength(6)] : [Validators.minLength(6)]),
      company: new UntypedFormControl('', [Validators.required]),
      website: new UntypedFormControl('', []),
      phone: new UntypedFormControl('', [Validators.pattern(/\+?\d+/)])
    };
    const controlsToShow = Array.from(this.inputsToShow.values())
      .reduce((obj, inputName) => ({ ...obj, [inputName]: allFormControls[inputName] }), {});

    return new UntypedFormGroup(controlsToShow);
  }

  submitForm() {
    if (this.registrationForm.invalid) {
      this.logAmplitude('Invalid form');
      markFormAsTouched(this.registrationForm);
      return;
    }
    const body = this.registrationForm.getRawValue() as User;
    this.onSubmit.emit(body);
  }

  loginClicked() {
    this.logAmplitude('Sign in clicked');
  }

  private logAmplitude(message: string) {
    this.amplitudeService.logEvent(`SSO:One view registration: ${message}`);
  }
}

export type RegistrationFormInput = 'name' | 'surname' | 'email' | 'password' | 'company' | 'website' | 'phone'
