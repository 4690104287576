import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { AuthenticateFromTokenResponse, MobileAuthToken } from './model/auth.model';
import { of } from 'rxjs';

@Injectable()
export class RegisterRequestsService {

  constructor(private http: HttpClient) {
  }

  save(user): Observable<any> {
    return this.http
      .post('/instance/', user);
  }

  invite(user): Observable<any> {
    return this.http
      .post('/invitation/register', user);
  }

  validateInvitationToken(token): Observable<any> {
    return this.http
      .get('/invitation/validate?token=' + token);
  }

  socialRegister(user): Observable<any> {
    return this.http
      .post('/invitation/register/social', user);
  }

  getUserFromToken(authToken: MobileAuthToken): Observable<AuthenticateFromTokenResponse> {
    return this.http.get<AuthenticateFromTokenResponse>('/mobile/email-from-token', { params: { ...authToken } });
  }

  isRegistrationEnabled(): Observable<any> {
    return this.http.get('/registration/is-enabled');
  }

  getAlternativeRegistrationSupportedCountries(): Observable<any> {
    return this.http.get('/registration/alternative/supported-countries');
  }

}
