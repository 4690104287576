import { Routes } from '@angular/router';
import { ChannelsLoginComponent } from '../channels/channels-login/channels-login.component';
import { ChannelsWelcomeComponent } from '../channels/channels-welcome/channels-welcome.component';

export const loginRoutes: Routes = [
  {
    path: 'login',
    children: [
      { path: '', component: ChannelsWelcomeComponent },
      { path: 'welcome-channels', component: ChannelsLoginComponent }
    ]
  }
];
