import { ErrorHandler, Injectable } from "@angular/core";
import * as Raven from "raven-js";
import { AppConfig } from "./app-config";

@Injectable()
export class RavenErrorHandler implements ErrorHandler {
  constructor(private AppConfig: AppConfig) {}

  handleError(err:any) : void {
    this.AppConfig.sentryEnabled
      ? Raven.captureException(err)
      : new ErrorHandler().handleError(err);
  }
}

export const SentryErrorHandler = { provide: ErrorHandler, useClass: RavenErrorHandler };
