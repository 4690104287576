import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { User } from '../../shared/register.model';
import { AmplitudeService } from '../../utils/amplitude.service';
import { RegisterService } from '../../shared/register.service';
import { RegisterHelperService } from '../../register/register-helper.service';
import { EmailService } from '../../shared/validators/email/email.service';
import { Router } from '@angular/router';
import { SocialRegisterProvider } from '../../register/register-social/register-social.type';
import { SocialRegisterHelperService } from '../../register/social-register-helper.service';
import { RegistrationFormInput } from './registration-form/registration-form.component';

@Component({
  selector: 'cc-channels-new-registration',
  templateUrl: './channels-new-registration.component.html',
  styleUrls: ['./channels-new-registration.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ChannelsNewRegistrationComponent implements OnInit {
  loading = false;
  afterSocial = false;
  inputsToShowInitially = new Set<RegistrationFormInput>(['name', 'surname', 'email', 'password', 'company', 'website', 'phone']);
  inputsToShowAfterSocial = new Set<RegistrationFormInput>();

  constructor(private amplitudeService: AmplitudeService,
              private registerService: RegisterService,
              private emailService: EmailService,
              private registerHelperService: RegisterHelperService,
              private socialRegisterHelper: SocialRegisterHelperService,
              private router: Router) {
  }

  ngOnInit() {
    this.logAmplitude('Init');
  }

  onSubmitForm(user: User) {
    if (this.loading) return;
    this.loading = true;
    const finalUser = this.registerService.updateUser(this.addStaticParams(user));
    const { password, ...amplitudeData } = finalUser;
    this.logAmplitude('Submitting form', amplitudeData);

    this.emailService.checkEmailNotExists(finalUser.email).subscribe((exists) => {
        if (exists) {
          this.logAmplitude('Email exists, go to login screen', amplitudeData);
          this.goToLoginScenario(finalUser);
        } else {
          this.logAmplitude('Email does not exist, about to register user', amplitudeData);
          this.goToRegisterScenario(finalUser);
        }
      },
      (error) => this.registerService.onRegisterError(error.error, '/final-register/error')
    );
  }

  // There is no clean and easy way to handle popup_closed event
  // (when user decides to close the oauth popup without a reason - no info nor error is pushed)
  // from providers (e.g. LiveChat), so we don't set this.loading = true here.
  // This will allow user to change the provider in the meanwhile
  // (multi-clicks are handled by libs - there will be always only one oauth popup open)
  async onSocialClicked(provider: SocialRegisterProvider) {
    if (this.loading) return;
    this.logAmplitude('Social button clicked', { provider });
    try {
      const user = await this.socialRegisterHelper.tryToRegisterWithSocialClient(provider);
      this.logAmplitude('Success after social button clicked', { provider, user });
      this.showAfterSocialForm();
      this.loading = false;
      return;
    } catch (error) {
      this.logAmplitude('Error after social button clicked', { provider, error });
      await this.router.navigate(['register', 'error']);
    }
  }

  private goToLoginScenario(user: User) {
    this.registerService.updateUser({ email: user.email });
    this.router.navigate(['/login']);
  }

  private goToRegisterScenario(user: User) {
    this.registerService.updateUser(this.addStaticParams(user));
    this.registerService.register(() => {
      this.logAmplitude('Instance created');
      this.loading = false;
      this.registerHelperService.resolveRegistrationType();
    });
  }

  private logAmplitude(message, event = null) {
    console.log(`SSO:One view registration: ${message}`, event);
    this.amplitudeService.logEvent(`SSO:One view registration: ${message}`, event);
  }

  private addStaticParams(user: User): User {
    return {
      accountName: user.company,
      companySize: '1-5',
      exists: false,
      extendedTrial: false,
      language: 'en',
      ...user
    };
  }

  private showAfterSocialForm() {
    this.afterSocial = true;
    const user = this.registerService.getUser();
    (Array.from(this.inputsToShowInitially.values()))
      .filter(value => value !== 'password') // no need to specify password with social register
      .filter(requiredField => !user[requiredField])
      .forEach(field => this.inputsToShowAfterSocial.add(field));
  }
}
