import { Routes } from '@angular/router';
import { DetailsGuard } from './register-3-steps/guards/details.guard';
import { ErrorGuard } from './guards/error.guard';
import { CompanyGuard } from './register-3-steps/guards/company.guard';
import { InviteGuard } from './guards/invite.guard';
import { VerifyPhoneNumberComponent } from './verify-phone-number/verify-phone-number.component';
import { VerifyPhoneNumberGuard } from './verify-phone-number/verify-phone-number.guard';
import { AccountVerifyComponent } from '../channels/account-verify/account-verify.component';
import { ChannelsErrorComponent } from '../channels/channels-error/channels-error.component';
import { ChannelsFinalRegisterComponent } from '../channels/channels-final-register/channels-final-register.component';
import { ChannelsInviteComponent } from '../channels/channels-invite/channels-invite.component';
import { ChannelsMainComponent } from '../channels/channels-main/channels-main.component';
import { ChannelsRegisterComponent } from '../channels/channels-register/channels-register.component';

export const registerRoutes: Routes = [
  {
    path: 'register', children: [
      {
        path: '',
        component: ChannelsRegisterComponent,
      },
      {path: 'details', component: ChannelsInviteComponent, canActivate: [DetailsGuard]},
      {path: 'error', component: ChannelsErrorComponent, canActivate: [ErrorGuard]},
    ],
  },
];
export const registerFinalRoutes: Routes = [
  {
    path: 'final-register', component: ChannelsMainComponent, children: [
      {path: '', component: ChannelsInviteComponent, pathMatch: 'full', canActivate: [InviteGuard]},
      {path: 'company', component: ChannelsFinalRegisterComponent, canActivate: [CompanyGuard]},
      {path: 'verify', component: VerifyPhoneNumberComponent, canActivate: [VerifyPhoneNumberGuard]},
      {path: 'error', component: ChannelsErrorComponent, canActivate: [ErrorGuard]},
      {path: 'account-verify', component: AccountVerifyComponent},
    ],
  }
];
