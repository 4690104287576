import { Injectable } from '@angular/core';
import { GtmService } from '../utils/gtm.service';
import { Router } from '@angular/router';
import { RegisterRequestsService } from '../register/register-requests.service';
import { AmplitudeService } from '../utils/amplitude.service';
import { UtmService } from '../utils/utm.service';
import GaService from '../utils/ga.service';
import AffiliatesParamsService from '../utils/affiliates.params.service';
import { VerifyPhoneNumberService } from '../register/verify-phone-number/verify-phone-number.service';
import { IpUserInfoService } from '../utils/ip-user-info.service';
import { ERROR_TEXTS, ERROR_TO_CODE, verificationPhoneNumberErrors } from '../register/register.config';
import { REGISTER_TYPE, SocialRegisterData } from '../register/register-social/register-social.type';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RegisterAlternativeRequestsService } from '../register-alternative/register-alternative-requests.service';
import { User } from './register.model';
import { AuthenticateFromTokenResponse, SignInAuthority } from '../register/model/auth.model';
import { CouponService } from "../utils/coupon.service";

@Injectable()
export class RegisterService {
  private user: User = {};
  public errorCode: string;
  public backUrl: string;
  public chanePhoneNumberUrl: string;
  private registerType: SocialRegisterData;
  public errorTexts: { TITLE: string; CONTENT: string; SHOW_SUPPORT: boolean };
  public onAltRegisterSuccess = new Subject();
  public onAltRegisterFailure = new Subject<string>();
  public onRegistrationDisabled = new BehaviorSubject<any>(opener);
  public onRegisterWithFormData = new BehaviorSubject<any>(opener);
  progressRef: NgProgressRef;

  constructor(private gtmService: GtmService,
              private ngProgress: NgProgress,
              private router: Router,
              private verifyPhoneNumberService: VerifyPhoneNumberService,
              private ipUserInfoService: IpUserInfoService,
              private amplitudeService: AmplitudeService,
              private registerRequestsService: RegisterRequestsService,
              private utmService: UtmService,
              private couponService: CouponService,
              private gaService: GaService,
              private affiliatesParamsService: AffiliatesParamsService,
              public registerAlternativeRequestsService: RegisterAlternativeRequestsService) {
  }

  updateUser(data) {
    Object.assign(this.user, data);
    const { password, email, ...userProperties } = this.user;
    this.amplitudeService.setUserProperties(userProperties);
    this.couponService.addCouponCodeCookie(this.user);
    if (email) {
      this.amplitudeService.setUserId(email);
    }
    return this.user;
  }

  getUser() {
    return this.user;
  }

  getUserName() {
    return this.user.name;
  }

  getUserSurame() {
    return this.user.surname;
  }

  getEmail() {
    return this.user.email;
  }

  getPassword() {
    return this.user.password;
  }

  hasEmail() {
    return this.user.email;
  }

  getCountry() {
    return this.user.country;
  }

  getCountryCode() {
    return this.user.countryCode;
  }

  hasDetails() {
    return this.user.name || this.user.exists;
  }

  userExists() {
    return this.user.exists;
  }

  setRegisterType(type) {
    this.registerType = type;
  }

  getRegisterType(): SocialRegisterData {
    return this.registerType;
  }

  trackEvent(event) {
    this.gtmService.push(event);
  }

  verify(errUrl = '/final-register/error') {
    this.backUrl = this.router.routerState.snapshot.url;
    this.verifyPhoneNumberService.sendCode({
      email: this.user.email,
      request: {
        verifyType: 'SMS',
        msisdn: this.user.phone,
        locale: this.ipUserInfoService.getCountry()
      }
    }).subscribe(() => {
      this.afterSendCodeTrack();
      this.router.navigate(['final-register', 'verify']);
    }, (errors) => {
      this.onRegisterError(errors.error, errUrl);
    });
  }

  register(callback, errUrl = '/final-register/error', verificationErrorCallback = null) {
    this.progressRef = this.ngProgress.ref('myProgress');
    this.progressRef.start();
    this.utmService.appendParamsToUser(this.user);
    this.gaService.addGACookie(this.user);
    this.affiliatesParamsService.addAffiliatesCookieParams(this.user);
    const lmref = this.getAffiliateRef();
    this.updateUser({ lmref });

    this.registerRequestsService.save(this.user).subscribe(instance => {
      this.amplitudeService.setUserId(this.user.email);
      this.amplitudeService.logEvent('SSO:Registration: Instance Created', {
        account: instance.domain
      });
      this.progressRef.complete();
      callback();
    }, (errors) => {
      if (errors.error.message === 'SSO_REGISTRATION_DISABLED') {
        this.amplitudeService.logEvent('SSO:Registration: Sso registration disabled');
        this.router.navigate(['/schedule-a-demo']);
        this.progressRef.complete();
        this.onRegistrationDisabled.next(this.user);
      } else {
        return this.onRegisterError(errors.error, errUrl, verificationErrorCallback);
      }
    });
  }

  alterRegister() {
    this.utmService.appendParamsToUser(this.user);
    this.gaService.addGACookie(this.user);
    this.affiliatesParamsService.addAffiliatesCookieParams(this.user);

    this.registerAlternativeRequestsService.registrationChecking(this.user).subscribe(
      () => this.onAltRegisterSuccess.next(null),
      errors => this.onAltRegisterFailure.next(RegisterService.onAlternativeRegisterError(errors.error))
    );
  }

  inviteValidateToken(token): Observable<any> {
    return this.registerRequestsService.validateInvitationToken(token);
  }

  inviteRegister(callback) {
    this.progressRef = this.ngProgress.ref('myProgress');
    this.progressRef.start();
    this.registerRequestsService.invite(this.user).subscribe(
      user => {
        this.user.email = user.email;
        callback();
      },
      errors => this.onRegisterError(errors.error, '/final-register/error'));
  }

  inviteSocialRegister(callback) {
    this.progressRef = this.ngProgress.ref('myProgress');
    this.progressRef.start();
    this.registerRequestsService.socialRegister(this.user).subscribe(
      () => callback(),
      errors => this.onRegisterError(errors.error, '/final-register/error'));
  }

  public getUserFromToken(token: string, signInAuthority: SignInAuthority): Observable<AuthenticateFromTokenResponse> {
    return this.registerRequestsService.getUserFromToken({ token, signInAuthority });
  }

  onRegisterError(errors, errUrl, verificationErrorCallback = null) {
    this.progressRef = this.ngProgress.ref('myProgress');
    if (errors instanceof Array && errors[0]) {
      this.amplitudeService.logEvent('SSO:Registration: Instance creation error', {
        errors,
        errUrl,
        code: ERROR_TO_CODE[errors[0]]
      });
      this.errorCode = RegisterService.randomCode(ERROR_TO_CODE[errors[0]] || ERROR_TO_CODE.UNKNOWN_ERROR);
      this.backUrl = RegisterService.shouldBeBlocked(ERROR_TO_CODE[errors[0]]) ? null : this.router.routerState.snapshot.url;
      this.errorTexts = ERROR_TEXTS[errors[0]] || ERROR_TEXTS[ERROR_TO_CODE.UNKNOWN_ERROR];
    } else {
      this.amplitudeService.logEvent('SSO:Registration: Instance creation error', {
        errors,
        errUrl,
        code: ERROR_TO_CODE.LOCALHOST_ERROR
      });
      this.errorCode = RegisterService.randomCode(ERROR_TO_CODE.LOCALHOST_ERROR);
      this.backUrl = this.router.routerState.snapshot.url;
      this.errorTexts = ERROR_TEXTS[ERROR_TO_CODE.UNKNOWN_ERROR];
    }

    this.progressRef.complete();

    if (verificationErrorCallback && errors instanceof Array && verificationPhoneNumberErrors[errors[0]]) {
      verificationErrorCallback();
    } else {
      this.router.navigateByUrl(errUrl);
    }
  }

  static onAlternativeRegisterError(errors): string {
    return RegisterService.randomCode(ERROR_TO_CODE[errors[0]] || ERROR_TO_CODE.UNKNOWN_ERROR);
  }

  static shouldBeBlocked(errorCode) {
    return errorCode === ERROR_TO_CODE.IP_ON_BLACKLIST || errorCode === ERROR_TO_CODE.POTENTIAL_EMAIL_FRAUD || errorCode === ERROR_TO_CODE.POTENTIAL_IP_FRAUD;
  }

  static randomCode(code) {
    return RegisterService.randomTwoDigits() + code.toString() + RegisterService.randomTwoDigits();
  }

  static randomTwoDigits() {
    return (Math.floor(Math.random() * 90) + 10);
  }

  afterSendCodeTrack() {
    if (this.userExists()) {
      this.trackEvent('sso-step2-instance');
    } else if (this.getRegisterType() === REGISTER_TYPE.FACEBOOK) {
      this.trackEvent('sso-step2-facebook');
    } else if (this.getRegisterType() === REGISTER_TYPE.GOOGLE) {
      this.trackEvent('sso-step2-google');
    } else {
      this.trackEvent('sso-step3-email');
    }
  }

  private getAffiliateRef(): string | undefined {
    // @ts-ignore
    return window.lmFinished && window.lmFinished();
  }

}
