import {Injectable} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {User} from "../shared/register.model";
import {CookieService} from "ngx-cookie-service";

const couponCode: string = 'couponCode';

@Injectable()
export class CouponService {

  constructor(private route: ActivatedRoute, private cookieService: CookieService) {
  }

  public addCouponCodeCookie(user: User) {
    let coupon = this.route.snapshot.queryParamMap.get(couponCode);
    if (coupon) {
      this.cookieService.set(couponCode, coupon);
    } else {
      coupon = this.cookieService.get(couponCode);
    }
    if (coupon) {
      user.couponCode = coupon;
    }
  }
}
