import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
  ViewChild, ElementRef, AfterViewInit
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
  selector: "shoper-connect-otp-phone-number",
  templateUrl: "./otp-phone-number.component.html",
  styleUrls: ["./otp-phone-number.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class OtpPhoneNumberComponent implements OnInit, AfterViewInit {
  phoneNumberGroup: UntypedFormGroup;
  @Input() loading = false;
  @Input() errorCode = false;
  @Output() submit = new EventEmitter<{ phoneNumber: string }>();
  @ViewChild('input') input: ElementRef;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.phoneNumberGroup = this.fb.group({
      phoneNumber: ["", Validators.required],
    });

  }
  ngAfterViewInit() {
    this.input.nativeElement.focus();
  }

  onSubmit() {
    const { phoneNumber } = this.phoneNumberGroup.getRawValue();
    this.submit.emit({ phoneNumber: `${phoneNumber}` });
  }
}
