<h3 class="channels-reset-email-title" translate>CHANNELS.RESET.FORGOT_YOU</h3>
<img class="channels-reset-email-icon" src="../../../../assets/img/channels/email-forgot-password.svg" alt="email-icon">
<p class="channels-reset-email-description" translate>CHANNELS.RESET.ENTER_EMAIL</p>

<form [formGroup]="resetForm"
      (ngSubmit)="reset()"
      class="form-wrapper validate"
      novalidate>
  <div class="channels-form-group">
    <input class="form-control"
           formControlName="email"
           [placeholder]="'EMAIL' | translate"
           autofocus>
    <cc-input-errors [form]="resetForm"
                     inputName="email"
                     id="email"
                     class="error-wrapper"
                     [errors]="[{'required':'ERROR.REQUIRED'},
                                {'pattern': 'ERROR.EMAIL'}]"></cc-input-errors>
  </div>
  <div class="channels-buttons-wrapper">
      <button class="channels-button"
              id="reset"
              [disabled]="resetForm.invalid"
              translate>RESET.SUBMIT
      </button>
  </div>
</form>
<p class="channels-reset-email-remember">
  <span translate>CHANNELS.RESET.REMEMBER</span>
  <a [routerLink]="['/login']"
     (click)="remember()"
     class="channels-reset-email-login-link"
     translate>CHANNELS.RESET.LOGIN</a>
</p>
