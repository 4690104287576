import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AppConfig } from "../../../config/app-config";
import { ResetService } from "../../../reset/reset.service";
import { AmplitudeService } from 'app/utils/amplitude.service';

@Component({
  selector: 'cc-channels-reset-email',
  templateUrl: './channels-reset-email.component.html',
  styleUrls: ['./channels-reset-email.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ChannelsResetEmailComponent implements OnInit {
  public resetForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
              private resetService: ResetService,
              private amplitudeService: AmplitudeService,
              private router: Router,
              private appConfig: AppConfig) { }

  ngOnInit() {
    this.amplitudeService.logEvent("SSO:Reset-Password: Init");
    this.createForm();
  }

  createForm() {
    this.resetForm = this.fb.group(({
      email: ['', Validators.compose([Validators.required, Validators.pattern(this.appConfig.emailPattern)])]
    }));
  }

  reset() {
    this.amplitudeService.logEvent("SSO:Reset-Password: Submit");
    this.resetService.email(this.resetForm.value.email).subscribe();
    this.router.navigate(['/reset', 'email', 'success'])
  }

  remember() {
    this.amplitudeService.logEvent("SSO:Reset-Password: Remember password, redirect to Login");
  }
}
