import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {RegisterService} from '../../../shared/register.service';
import {AmplitudeService} from '../../../utils/amplitude.service';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class EmailGuard implements CanActivate {
  constructor(private RegisterService: RegisterService,
              private amplitudeService: AmplitudeService,
              private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const params = state.root.queryParams;
    this.amplitudeService.logEvent('SSO: Page View: Registration (email)', {
      ...params
    });
    this.amplitudeService.setUserProperties({ ...params });
    this.RegisterService.updateUser({source: params.source});
    if (params.email) {
      this.RegisterService.updateUser({email: params.email});
      this.router.navigate(['/register', 'details']);
    }
    return true;
  }
}
