import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

interface IExistEmail{
  exists: boolean
}
@Injectable()
export class EmailService {
  constructor(private http: HttpClient) {}

  checkEmailNotExists(email): Observable<boolean> {
    const codedEmail = email.replace('+', '%2B').replace('@', '%40');
    return this.http
      .get<IExistEmail>('/user/email/exists?email=' + codedEmail).pipe(
        map(res => res.exists)
      )
  }
}
