import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChannelsRegisterComponent } from './channels-register/channels-register.component';
import { ChannelsMainComponent } from './channels-main/channels-main.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UtilsModule } from '../utils/utils.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ChannelsLoginComponent } from './channels-login/channels-login.component';
import { LoginModule } from '../login/login.module';
import { MatIconModule } from '@angular/material/icon';
import { ChannelsErrorComponent } from './channels-error/channels-error.component';
import { ChannelsWelcomeComponent } from './channels-welcome/channels-welcome.component';
import { SocialButtonsGroupComponent } from './social-buttons-group/social-buttons-group.component';
import { ChannelsFinalRegisterComponent } from './channels-final-register/channels-final-register.component';
import { RegisterModule } from '../register/register.module';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { ChannelsResetComponent } from './channels-reset/channels-reset.component';
import { ChannelsResetInfoComponent } from './channels-reset-info/channels-reset-info.component';
import { ChannelsInviteComponent } from './channels-invite/channels-invite.component';
import { ChannelsDetailsComponent } from './channels-details/channels-details.component';
import { ChannelsResetEmailComponent } from './channels-reset/channels-reset-email/channels-reset-email.component';
import { AccountVerifyComponent } from './account-verify/account-verify.component';
import { ChannelsNewRegistrationComponent } from './channels-new-registration/channels-new-registration.component';
import { ChannelsRegisterSwitchComponent } from './channels-register-switch/channels-register-switch.component';
import { RegistrationFormComponent } from './channels-new-registration/registration-form/registration-form.component';
import { SocialButtonComponent } from './social-button/social-button.component';
import { ChannelsTheBestComponent } from './channels-the-best/channels-the-best.component';
import { ChannelsFeatureComponent } from './channels-the-best/channels-feature/channels-feature.component';
import {GoogleSigninButtonModule} from "@abacritt/angularx-social-login";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        UtilsModule,
        ReactiveFormsModule,
        SharedModule,
        LoginModule,
        MatIconModule,
        RegisterModule,
        MatRadioModule,
        GoogleSigninButtonModule
    ],
  declarations: [
    ChannelsRegisterComponent,
    ChannelsMainComponent,
    ChannelsLoginComponent,
    ChannelsErrorComponent,
    ChannelsWelcomeComponent,
    SocialButtonsGroupComponent,
    ChannelsFinalRegisterComponent,
    ChannelsResetComponent,
    ChannelsResetInfoComponent,
    ChannelsInviteComponent,
    ChannelsDetailsComponent,
    ChannelsResetEmailComponent,
    AccountVerifyComponent,
    ChannelsNewRegistrationComponent,
    ChannelsRegisterSwitchComponent,
    RegistrationFormComponent,
    SocialButtonComponent,
    ChannelsTheBestComponent,
    ChannelsFeatureComponent,




  ],
    exports: [ChannelsMainComponent,
        ChannelsRegisterComponent,
        ChannelsLoginComponent,
        ChannelsErrorComponent,
        SocialButtonsGroupComponent,
        ChannelsWelcomeComponent,
        ChannelsFinalRegisterComponent,
        ChannelsResetComponent,
        ChannelsResetInfoComponent,
        ChannelsInviteComponent,
        ChannelsResetEmailComponent]
})
export class ChannelsModule { }
