import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ShoperConnectService } from "../shoper-connect.service";
import { TokenResponse } from "../shoper-connect.model";
import { ActivatedRoute } from "@angular/router";
import { environment } from "../../../environments/environment";
import { AmplitudeService } from 'app/utils/amplitude.service';

@Component({
  selector: "cc-otp",
  templateUrl: "./otp.component.html",
  styleUrls: ["./otp.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class OtpComponent implements OnInit {
  currentStep: "PHONE_NUMBER" | "VERIFICATION" = "PHONE_NUMBER";
  phoneNumber: string;
  loading = false;
  errorCode = false;

  otpForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private service: ShoperConnectService,
    private route: ActivatedRoute,
    private amplitudeService: AmplitudeService
  ) {}

  ngOnInit() {
    this.otpForm = this.fb.group({
      phoneNumber: [""],
      validationCode: [""],
    });

    this.updateHeadMeta();
  }

  allowToVerify({ phoneNumber }) {
    this.errorCode = false;
    this.loading = true;
    this.service.verifyMsisdn(phoneNumber).subscribe(
      () => {
        this.loading = false;
        this.phoneNumber = phoneNumber;
        this.currentStep = "VERIFICATION";
      },
      (e) => {
        this.errorCode = true;
        this.amplitudeService.logEvent('Shoper:SSO:Login failed', { phoneNumber } );
        this.loading = false;
      }
    );
  }

  verifyPhoneNumber({ code }) {
    if (!code) return;
    this.errorCode = false;
    this.loading = true;

    // TODO: fix SSO frontend build process to NOT overwrite qa13 build dist by prod
    const redirectUri =
      this.route.snapshot.queryParams["redirect_uri"] ||
      `${
        window.location.hostname === "qa13-sso.aws-whitelabel.ch"
          ? "https://qa13-app.aws-whitelabel.ch"
          : environment.SHOPER_CONNECT_URL
      }/login`;

    this.service.verifyCode(this.phoneNumber, code).subscribe(
      (token: TokenResponse) => {
        this.loading = false;
        window.location.href = `${redirectUri}?access_token=${token.accessToken}`;
      },
      () => {
        this.errorCode = true;
        this.amplitudeService.logEvent('Shoper:SSO:Wrong code' );
        this.loading = false;
      }
    );
  }

  restartFlow() {
    this.currentStep = "PHONE_NUMBER";
    this.loading = false;
    this.errorCode = false;
  }

  private updateHeadMeta() {
    document.title = "Shoper Connect";
    (document.querySelector("#favicon") as HTMLLinkElement).href =
      "assets/img/shoper-connect/favicon.png";
  }
}
