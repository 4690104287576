import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'cc-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss']
})
export class InputErrorComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() inputName: string;
  @Input() error: string;
  @Input() text: string;

  constructor() { }

  ngOnInit() {
  }

}
