import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { REGISTER_TYPE, SocialRegisterProvider } from '../../register/register-social/register-social.type';
import {SocialAuthService} from "@abacritt/angularx-social-login";

@Component({
  selector: 'cc-social-button',
  templateUrl: './social-button.component.html',
  styleUrls: ['./social-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SocialButtonComponent implements OnInit {
  @Input() text: string;
  @Input() provider: SocialRegisterProvider;
  @Output() socialClicked = new EventEmitter<SocialRegisterProvider>();

  @Input() buttonId = '';

  imageSrc: string;

  constructor( private authService: SocialAuthService) {
  }

  ngOnInit(): void {
    this.imageSrc = REGISTER_TYPE[this.provider].imageSrc;
    if (!this.buttonId) {
      this.buttonId = `login-${this.provider.toLocaleLowerCase()}`;
    }
  }
}
