export function localStorageSupported(): boolean {
  const testItem = 'temp';
  try {
    localStorage.setItem(testItem, testItem);
    localStorage.removeItem(testItem);
    return true;
  } catch(e) {
    return false;
  }
}
