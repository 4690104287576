<h2 *ngIf="labels.title" class="channels-reset-info-title"
    [innerHtml]="'CHANNELS.'+labels.title | translate"></h2>
<img class="channels-reset-info-icon" [src]="'/assets/img/channels/' + labels.icon + '.svg'" alt="{{labels.icon}}">

<p *ngIf="labels.content" class="channels-reset-info-description">
  {{'CHANNELS.'+ labels.content | translate}}</p>
<p *ngIf="labels.footer"  class="channels-reset-info-description">
  {{'CHANNELS.'+ labels.footer | translate}}</p>
<button class="channels-button"
        *ngIf="labels.button"
        [routerLink]="['/login']">{{'CHANNELS.'+labels.button | translate}}
</button>
<div *ngIf="labels.support"
     class="channels-reset-info-instruction">
  <span>{{'CHANNELS.'+labels.support | translate}}</span>
  <a (click)="contactSupport()"
     class="channels-reset-info-instruction-pointer">{{'RESET.SUPPORT' | translate}}</a>
</div>
