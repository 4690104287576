import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RegisterService } from '../../shared/register.service';
import { AmplitudeService } from '../../utils/amplitude.service';
import { RegisterHelperService } from '../../register/register-helper.service';
import { LanguageService } from '../../shared/services/language.service';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import { EmailService } from '../../shared/validators/email/email.service';
import { Router } from '@angular/router';
import { RegisterAlternativeRequestsService } from '../../register-alternative/register-alternative-requests.service';
import { RegisterRequestsService } from '../../register/register-requests.service';
import { User } from '../../shared/register.model';

@Component({
  selector: 'cc-channels-final-register',
  templateUrl: './channels-final-register.component.html',
  styleUrls: ['./channels-final-register.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ChannelsFinalRegisterComponent implements OnInit {
  public exist: boolean;
  public registerForm: UntypedFormGroup;
  public email: string;
  public name: string;
  public surname: string;
  public password: string;
  public isPending: boolean;
  progressRef: NgProgressRef;

  constructor(private registerService: RegisterService,
              private amplitudeService: AmplitudeService,
              private registerHelperService: RegisterHelperService,
              private languageService: LanguageService,
              public ngProgress: NgProgress,
              public emailService: EmailService,
              public router: Router,
              private registerAlternativeRequestsService: RegisterAlternativeRequestsService,
              private registerRequestsService: RegisterRequestsService) {
  }

  ngOnInit() {
    this.progressRef = this.ngProgress.ref('myProgress');
    this.exist = this.registerService.userExists();
    this.email = this.registerService.getEmail();
    this.name = this.registerService.getUserName();
    this.surname = this.registerService.getUserSurame();
    this.password = this.registerService.getPassword();
    this.createForm();
    if (!this.isUserCompleteSoFar() && !this.registerService.getRegisterType()) {
      this.router.navigate(['/login']);
    } else {
      this.updateForm();
    }
    this.amplitudeService.logEvent('SSO:Registration:Final: Init');
    this.trackEvents();
  }

  isUserCompleteSoFar() {
    return this.email && this.name && this.surname && this.password;
  }

  createForm(): void {
    const fields = this.exist ? ['accountName', 'phone'] : ['companySize', 'accountName', 'phone', 'website'];
    this.registerForm = this.registerHelperService.createForm(fields);
  }

  updateForm() {
    this.registerHelperService.updateForm(this.registerForm);
  }

  getPhoneErrors() {
    return this.registerForm.get('phone').errors;
  }

  register() {
    const loginParams = {
      company: this.registerForm.get('accountName').value.trim(),
      phoneNumber: !!this.registerForm.get('phone').value ? this.registerForm.get('phone').value : null,
      website: !!this.registerForm.get('website').value ? this.registerForm.get('website').value : null,
      countryCode: this.registerService.getCountryCode() || null,
      country: this.registerService.getCountry() || null
    };
    this.progressRef.start();
    this.registerForm.markAsPending();
    this.isPending = true;
    if (this.registerForm.get('accountName').valid) {
      this.emailService.checkEmailNotExists(this.email).subscribe((exists) => {
          if (exists) {
            this.goToLoginScenario();
          } else {
            this.goToRegisterScenario(loginParams);
          }
        },
        (error) => {
          this.progressComplete();
          this.registerService.onRegisterError(error.error, '/final-register/error');
        }
      );
    } else {
      this.progressComplete();
    }
  }

  goToLoginScenario() {
    this.registerService.updateUser({ email: this.email });
    this.progressComplete();
    this.router.navigate(['login', 'welcome-channels']);
  }

  goToRegisterScenario(loginParams) {
    this.registerService.updateUser(this.getStandardLoginParams(loginParams));
    this.languageService.setLangToUser();

    this.registerRequestsService.getAlternativeRegistrationSupportedCountries().subscribe((countries: string[]) => {
      const { countryCode } = loginParams;
      if (countries.includes(countryCode)) {
        this.amplitudeService.logEvent('SSO:Registration:Final: Start alternative registration', { countryCode });
        this.registerAlternativeRequestsService.registrationChecking(
          this.getGoogleLoginParams(loginParams)).subscribe(
          () => {
            this.progressComplete();
            this.router.navigate(['/final-register', 'account-verify']);
          },
          errors => this.registerService.onRegisterError(errors.error, '/final-register/error')
        );
      } else {
        this.trackEvents();
        this.registerService.register(() => {
          this.registerHelperService.resolveRegistrationType();
        });
      }
    });
  }

  trackEvents() {
    const registerType = this.registerService.getRegisterType();
    this.registerService.trackEvent('sso-step2-' + (registerType && registerType.name ? registerType.name : 'email'));
  }

  progressComplete() {
    this.progressRef.complete();
    this.isPending = false;
  }

  getStandardLoginParams(params): User {
    return {
      accountName: params.company,
      companySize: '1-5',
      country: params.country,
      countryCode: params.countryCode,
      email: this.email,
      exists: false,
      extendedTrial: false,
      language: 'en',
      linkCode: null,
      name: this.name,
      password: this.password,
      phone: params.phoneNumber,
      surname: this.surname,
      website: params.website
    };
  }

  getGoogleLoginParams(params) {
    return {
      email: this.email,
      firstName: this.name,
      lastName: this.surname,
      company: params.company,
      phoneNumber: params.phoneNumber,
      website: params.website,
      countryCode: params.countryCode,
      country: params.country,
      language: 'en'
    };
  }

}
