import { Routes } from '@angular/router';
import { ResetTokenGuard } from './guards/reset-token.guard';
import { ChannelsResetEmailComponent } from '../channels/channels-reset/channels-reset-email/channels-reset-email.component';
import { ChannelsResetInfoComponent } from '../channels/channels-reset-info/channels-reset-info.component';
import { ChannelsResetComponent } from '../channels/channels-reset/channels-reset.component';

export const resetRoutes: Routes = [
  {
    path: 'reset', children: [
      {
        path: '', component: ChannelsResetComponent,
        canActivate: [ResetTokenGuard]
      },
      { path: 'email', component: ChannelsResetEmailComponent },
      {
        path: 'success', component: ChannelsResetInfoComponent,
        data: {
          labels: {
            title: 'RESET.SUCCESS',
            content: 'RESET.SUCCESS_CHANGE',
            button: 'LOGIN.LABEL',
            icon: 'password-set-icon'
          }
        }
      },
      {
        path: 'email/success', component: ChannelsResetInfoComponent,
        data: {
          labels: {
            title: 'RESET.LABEL', content: 'RESET.SUCCESS_DETAIL', footer: 'RESET.CHECK_EMAIL',
            button: 'RESET.GO_BACK', support: 'RESET.IF_SEND_EMAIL', icon: 'email-password-send'
          }
        }
      },
      {
        path: 'error', component: ChannelsResetInfoComponent, data: {
          labels: { title: 'RESET.WRONG_TOKEN', button: 'BACK' }
        }
      }
    ]
  }
];
