import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { SocialRegisterProvider } from '../../register/register-social/register-social.type';

@Component({
  selector: 'cc-social-buttons-group',
  templateUrl: './social-buttons-group.component.html',
  styleUrls: ['./social-buttons-group.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SocialButtonsGroupComponent implements AfterViewInit {
  @Input() providers: SocialButtonProvider[];
  @Output() providerClick = new EventEmitter<SocialRegisterProvider>();

  socialButtonWidth: number;
  constructor(private ref: ChangeDetectorRef) {
  }
  ngAfterViewInit() {
    this.observeSocialButtonWidth();
  }
  observeSocialButtonWidth() {
    const element = document.querySelector('.social-button')
    const observer = new ResizeObserver((el) => {
          this.socialButtonWidth = null;
            this.socialButtonWidth = Math.ceil(el[0].borderBoxSize[0].inlineSize);
    });
    observer.observe(element);
  }
  getSocialButtonWidth() {
    return this.socialButtonWidth < 389 ? (this.socialButtonWidth + 'px') : '389px';
  }
}

export interface SocialButtonProvider {
  provider: SocialRegisterProvider;
  text: string;
}
