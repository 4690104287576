<h3 class="channels-final-register-title" translate>CHANNELS.REGISTER.FINAL_TITLE</h3>
<p class="channels-final-register-description" translate>CHANNELS.REGISTER.FINAL_CONTENT</p>

<form [formGroup]="registerForm"
      class="form-wrapper"
      (ngSubmit)="register()"
      ccScrollToFirstInvalidInput>
  <div class="channels-form-group">

    <input formControlName="accountName"
           class="form-control"
           autocomplete="new-accountName"
           [placeholder]="'CHANNELS.REGISTER.ACCOUNT_NAME' | translate"
           data-hj-whitelist
           id="account-name">
    <cc-input-errors [form]="registerForm"
                     inputName="accountName"
                     class="error-wrapper"
                     [errors]="[{'required': 'ERROR.REQUIRED'},
                                {'character': 'ERROR.CHARACTER'},
                                {'empty': 'ERROR.NON_SPACE'}]"></cc-input-errors>
  </div>
  <div class="channels-form-group"
       *ngIf="!exist">
    <input formControlName="website"
           id="website"
           class="form-control"
           data-hj-whitelist
           [placeholder]="'CHANNELS.REGISTER.WEBSITE' | translate">
  </div>
  <div class="channels-form-group">
    <cc-intl-phone-number-input [class.ng-invalid]="getPhoneErrors()"
                                data-hj-whitelist
                                formControlName="phone"></cc-intl-phone-number-input>
    <cc-input-error [form]="registerForm"
                    inputName="phone"
                    error="phone"
                    class="error-wrapper"
                    text="ERROR.PHONE"></cc-input-error>
  </div>
  <button class="btn channels-button"
          [class.disabled]="isPending"
          id="register"
          translate>CHANNELS.REGISTER.CREATE_ACCOUNT
  </button>
</form>
<cc-policy></cc-policy>
