import { SocialUser } from '@abacritt/angularx-social-login';
import { BasicUser } from '../model/auth.model';

export class GoogleUserParseService {
  static parseFromGoogleUser(user: BasicUser | SocialUser) {
    let firstSpace = user.name.indexOf(' ');
    user.firstName = user.name.substr(0, firstSpace);
    user.lastName = user.name.substr(firstSpace + 1);
  }
}
