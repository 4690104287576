<section class="main-wrapper">
  <div class="info">
  <img
    class="logo"
    alt="Channels"
    src="assets/img/channels/logo-ch-green.svg">


  <h1 class="title" translate>CHANNELS.REGISTRATION.SMARTEST</h1>

  <cc-channels-feature textKey="CHANNELS.REGISTRATION.FEATURE_1"></cc-channels-feature>
  <cc-channels-feature textKey="CHANNELS.REGISTRATION.FEATURE_2"></cc-channels-feature>
  <cc-channels-feature textKey="CHANNELS.REGISTRATION.FEATURE_3"></cc-channels-feature>

  <img
    class="ratings"
    alt="Ratings"
    src="assets/img/channels/ratings.svg">

  </div>
</section>
