import { Routes } from '@angular/router';
import { VerifyGuard } from '../utils/guards/verify.guard';
import { EmptyComponent } from '../utils/empty/empty.component';
import { registerFinalRoutes, registerRoutes } from '../register/register.routes';
import { livechatRoutes } from '../livechat/livechat.routes';
import { ChannelsNewRegistrationComponent } from '../channels/channels-new-registration/channels-new-registration.component';
import { resetRoutes } from '../reset/reset.routes';
import { loginRoutes } from '../login/login.routes';
import { ChannelsMainComponent } from '../channels/channels-main/channels-main.component';
import { ChannelsRegisterSwitchComponent } from '../channels/channels-register-switch/channels-register-switch.component';
import { shoperConnectRoutes } from "../shoper-connect/shoper-connect.routes";

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '', component: ChannelsMainComponent,
    children: [
      ...loginRoutes,
      ...registerRoutes,
      ...resetRoutes
    ]
  },
  ...registerFinalRoutes,
  ...livechatRoutes,
  ...shoperConnectRoutes,
  { path: 'verify', component: EmptyComponent, canActivate: [VerifyGuard] },
  {
    path: 'new-account',
    component: ChannelsNewRegistrationComponent,
  },
  {
    path: 'register-switch',
    component: ChannelsRegisterSwitchComponent,
  }
];
