import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { LiveChatService } from "../../utils/live-chat.service";

@Component({
  selector: 'cc-channels-reset-info',
  templateUrl: './channels-reset-info.component.html',
  styleUrls: ['./channels-reset-info.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ChannelsResetInfoComponent implements OnInit {
  public labels: { [key: string]: any };

  constructor(private route: ActivatedRoute, private liveChatService: LiveChatService) { }

  ngOnInit() {
    this.labels = this.route.snapshot.data.labels;
  }

  contactSupport(){
    this.liveChatService.contactSupport();
  }
}
