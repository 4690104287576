import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'cc-input-errors',
  templateUrl: './input-errors.component.html',
  styleUrls: ['./input-errors.component.scss']
})
export class InputErrorsComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() inputName: string;
  @Input() errors: Array<any>;
  @Input() text: any;

  public error: any;

  constructor() {
  }

  ngOnInit(): void {
    this.getFirstError();
    this.form.get(this.inputName).statusChanges.subscribe(() => this.getFirstError());
  }

  getFirstError() {
      this.errors.reverse().forEach((el, index) => {
        const key = Object.keys(el)[0];
        if (this.form.get(this.inputName).hasError(key)) {
          this.error = {error: key, text: this.errors[index][key]};
        }
      });
  }
}
