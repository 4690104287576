<article class="background">
  <section class="content" *ngIf="receivedResponse && !accountNotCreated">
    <img src="assets/img/shoper-connect/info-finish.svg" decoding="sync">

    <h1 class="header">Gratulacje, właśnie usprawniłeś swój e-commerce!</h1>
    <p class="description">Twoje konto jest już gotowe. Już teraz możesz zacząć wysyłać kampanie SMS do klientów, zbudować profesjonalną infolinię, czy zbierać leady bezpośrednio z Twojego sklepu. Wszystko to będziesz w stanie ustawić w dedykowanym panelu w przeglądarce. Pamiętaj, że aby być w kontakcie z klientami musisz być zalogowany do jednej z naszych aplikacji, które możesz pobrać poniżej.</p>
<!--    <p class="description">Pamiętaj, że aby wykonywać i odbierać połączenia, musisz zainstalować naszą aplikację mobilną. Pobierz ją korzystając z przycisków poniżej. Jeśli tego nie zrobisz, klienci nie będą w stanie się z Tobą skontaktować.</p>-->
<!--    <p class="description">Kiedy już pobierzesz aplikację Shoper Connect, zaloguj się do niej podając swój numer telefonu. </p>-->
    <div class="buttons">
      <button class="shoper-button" (click)="openMobileStore('android')">Aplikacja na Android</button>
      <button class="shoper-button" (click)="openMobileStore('iOS')">Aplikacja na iOS</button>
      <button class="shoper-button" (click)="goToManagerPanel()">Panel w przeglądarce</button>
      <!-- Revert back when desktop app is available -->
<!--      <button class="shoper-button" (click)="goToManagerPanel()">Aplikacja na desktop</button>-->
    </div>
  </section>

  <div *ngIf="!accountNotCreated && !receivedResponse" class="loader-wrapper">
    <span class="a-loader a-loader_xl"></span>
  </div>
  <ng-container *ngIf="accountNotCreated">
    <div class="first-steps-error pt-14">
      <img src="assets/img/shoper-connect/first_steps_error.png" />
      <h2 class="header">Niestety nie udało nam się stworzyć Twojego konta</h2>
      <p class="description">Przepraszamy, ale podczas tworzenia Twojego konta wystąpił błąd.
        Prosimy, spróbuj ponownie klikając w przycisk poniżej.</p>
      <div class="buttons">
        <button class="shoper-button" (click)="reloadFirstSteps()">Spróbuj ponownie</button>
      </div>
    </div>
  </ng-container>
</article>
