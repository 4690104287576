import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from "@angular/router";
import { Location } from '@angular/common';
import { LiveChatService } from '../../utils/live-chat.service';
import { RegisterService } from "../../shared/register.service";

@Component({
  selector: 'cc-channels-error',
  templateUrl: './channels-error.component.html',
  styleUrls: ['./channels-error.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ChannelsErrorComponent implements OnInit {
  public code: string;
  public url: string;
  public errorTexts: { TITLE: string; CONTENT: string; SHOW_SUPPORT: boolean };

  constructor(
    public router: Router,
    private location: Location,
    public liveChatService: LiveChatService,
    private registerService: RegisterService) { }

  ngOnInit() {
    this.code = this.registerService.errorCode;
    this.url  = this.registerService.backUrl;
    this.errorTexts = this.registerService.errorTexts;
  }

  back() {
    if (this.url) {
      this.router.navigateByUrl(this.url)
    } else {
      this.location.back();
    }
  }

  contactSupport() {
    this.liveChatService.contactSupport()
  }
}
