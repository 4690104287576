import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cc-channels-feature',
  templateUrl: './channels-feature.component.html',
  styleUrls: ['./channels-feature.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ChannelsFeatureComponent implements OnInit {
  @Input() textKey: string;

  constructor() { }

  ngOnInit() {
  }

}
