import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LanguageSelectComponent } from "./language-select/language-select.component";
import { ScrollToFirstInvalidInputDirective } from "./scroll-to-first-invalid-input/scroll-to-first-invalid-input.directive";
import { MaterialInputComponent } from "./material-input/material-input.component";
import { MaterialModule } from "../material/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MaterialButtonComponent } from "./material-button/material-button.component";
import { AllowOnlyDirective } from "./allow-only.directive";
import GaService from '../utils/ga.service';
import AffiliatesParamsService from '../utils/affiliates.params.service';

@NgModule({
  imports: [CommonModule, MaterialModule, TranslateModule, ReactiveFormsModule],
  declarations: [
    LanguageSelectComponent,
    ScrollToFirstInvalidInputDirective,
    MaterialInputComponent,
    MaterialButtonComponent,
    AllowOnlyDirective,
  ],
  exports: [
    LanguageSelectComponent,
    ScrollToFirstInvalidInputDirective,
    MaterialInputComponent,
    MaterialButtonComponent,
    AllowOnlyDirective,
  ],
  providers: [GaService, AffiliatesParamsService ]
})
export class SharedModule {}
