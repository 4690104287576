import { Injectable } from '@angular/core';
import { EmailService } from '../shared/validators/email/email.service';
import { RegisterHelperService } from './register-helper.service';
import { SocialAuthService , GoogleLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import { LiveChatOauthService } from '../utils/live-chat.oauth.service';
import { AppleOauthService } from '../utils/apple.oauth.service';
import { REGISTER_TYPE, SocialRegisterData, SocialRegisterProvider } from './register-social/register-social.type';
import { BasicUser } from './model/auth.model';
import { GoogleUserParseService } from './register-social/google-user-parse.service';
import { RegisterService } from '../shared/register.service';

@Injectable({
  providedIn: 'root'
})
export class SocialRegisterHelperService {

  constructor(private registerService: RegisterService,
              private emailService: EmailService,
              private registerHelperService: RegisterHelperService,
              private authService: SocialAuthService,
              private liveChatOauthService: LiveChatOauthService,
              private appleOauthService: AppleOauthService) {
  }


  async   tryToRegisterWithSocialClient(provider: SocialRegisterProvider, socialUser?): Promise<SocialUser | BasicUser> {
    switch (provider) {
      case 'APPLE': {
        const { user, socialData } = await this.appleOauthService.authorize();
        return this.checkIfExistsAndUpdate(user, socialData);
      }
      case 'LIVECHAT': {
        const user = await this.liveChatOauthService.login();
        return this.checkIfExistsAndUpdate(user, REGISTER_TYPE.LIVECHAT);
      }
      case 'GOOGLE': {
        return this.checkIfExistsAndUpdate(socialUser, REGISTER_TYPE.GOOGLE);
      }
      default:
        return Promise.reject('Unknown social provider ' + provider);
    }
  }

  private async checkIfExistsAndUpdate(user: SocialUser | BasicUser, registerType: SocialRegisterData): Promise<SocialUser | BasicUser> {
    const exists = await this.emailService.checkEmailNotExists(user.email).toPromise();
    this.registerService.setRegisterType(registerType);

    this.registerService.updateUser({ email: user.email, name: user.firstName, surname: user.lastName, exists });
    const registerTypeStr = registerType.name;

    if (exists) {
      await this.registerHelperService.socialLogin();
      this.registerService.trackEvent('login-login-' + registerTypeStr);
    } else {
      this.registerService.trackEvent('sso-step1-' + registerTypeStr);
    }
    return user;
  }

}
