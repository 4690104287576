import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { Params } from "@angular/router";
import { TokenResponse } from "./shoper-connect.model";

@Injectable({
  providedIn: "root",
})
export class ShoperConnectService {
  constructor(private http: HttpClient) {}

  registerInstance(params: Params): Observable<TokenResponse | null> {
    return this.http.post<TokenResponse | null>("/shoper-connect/instance", params);
  }

  verifyMsisdn(phoneNumber: string): Observable<any> {
    return this.http.post("/shoper-connect/verify", {
      msisdn: this.normalizeMsisdn(phoneNumber),
    });
  }

  verifyCode(phoneNumber: string, code: string): Observable<TokenResponse> {
    return this.http.post<TokenResponse>("/shoper-connect/login", {
      msisdn: this.normalizeMsisdn(phoneNumber),
      verificationCode: code,
    });
  }

  private normalizeMsisdn(phoneNumber: string) {
    return phoneNumber.startsWith("+") ? phoneNumber : `+48${phoneNumber}`;
  }
}
