import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '../utils/utils.module';
import { RouterModule } from '@angular/router';

import { ResetService } from './reset.service';
import { ResetTokenGuard } from './guards/reset-token.guard';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    UtilsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule
  ],
  providers: [
    ResetService,
    ResetTokenGuard
  ],
  declarations: [],
  exports: []
})
export class ResetModule {
}
