<button class="social-button"
        (click)="socialClicked.emit(provider)"
        [id]="buttonId"
>
  <img [src]="imageSrc"
       class="icons-size radius"
       alt="">
  <div class="social-button-title">
    {{text | translate}}
  </div>
</button>
