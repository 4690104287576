import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "./shared/services/language.service";
import * as localEn from "../assets/i18n/en.json";

import { IpUserInfoService } from "./utils/ip-user-info.service";
import { LiveChatService } from "./utils/live-chat.service";
import { RegisterService } from "./shared/register.service";
import { UtmService } from "./utils/utm.service";
import { SentryService } from "./utils/sentry.service";
import { environment } from "../environments/environment";
import { localStorageSupported } from "./shared/utils/localStorageUtils";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  template:
    '<ng-progress  id="myProgress" [color]="\'#21D196\'" [spinner]="true"></ng-progress><router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  constructor(
    private sentryService: SentryService,
    private translate: TranslateService,
    private ipUserInfoService: IpUserInfoService,
    private utmService: UtmService,
    private liveChatService: LiveChatService,
    private registerService: RegisterService,
    private languageService: LanguageService,
    private router: Router
  ) {
    if (!localStorageSupported()) {
      window.location.href = environment.CANNOT_USE_APP_URL;
      return;
    }

    sentryService.setInitialParams();
    translate.setDefaultLang("en");
    ipUserInfoService.getIpInfo().then(() => {
      registerService.updateUser({
        country: ipUserInfoService.getFullNameCountry(),
        countryCode: ipUserInfoService.getCountry(),
      });
    });
    liveChatService.initUserInfo();
    utmService.saveParams();
  }

  ngOnInit() {
    this.guardChannelsAndShoperConnectPaths();

    const languageFromCookie = this.languageService.getCookieLanguage();
    const selectedLanguage = this.languageService.selectLanguageBasedOnBrowserSettings();

    this.fetchTranslations(languageFromCookie || selectedLanguage);
    this.setUpSignInWithApple();
  }

  fetchTranslations(lang: string) {
    this.translate.setDefaultLang(lang);
    this.translate.setTranslation("en", localEn);
    this.useLanguage("en");
  }

  useLanguage(lang) {
    this.languageService.useLanguage(lang);
  }

  private setUpSignInWithApple() {
    AppleID.auth.init({
      clientId: environment.SIGN_IN_WITH_APPLE_CLIENT_ID,
      scope: "name email",
      redirectURI: environment.CHANNELS_SSO_URL,
      usePopup: true,
    });
  }

  private guardChannelsAndShoperConnectPaths() {
    const { href, hash } = window.location;
    if (href.includes("localhost")) {
      return;
    }

    // TODO: remove hardcoded entries once SSO build preprod / prod is fixed
    const shoperApp = href.includes(environment.SHOPER_CONNECT_SSO_DOMAIN) || href.includes('shoperconnect.pl');
    const channelsApp = href.includes(environment.CHANNELS_SSO_URL) || href.includes('channels.app');

    const shoperHash = ["#/otp"].includes(hash);
    const channelsHash = ["", "#/", "#/login", "#/register"].includes(hash);

    if (shoperApp && channelsHash) {
      this.router.navigateByUrl("otp");
    }
    if (channelsApp && shoperHash) {
      this.router.navigateByUrl("login");
    }
  }
}
