<h3 class="channels-login-title" translate>CHANNELS.LOGIN.TITLE</h3>
<div class="channels-login-alert"
     role="alert"
     *ngIf="error">
  <i class="material-icons text-danger">cancel</i>
  <span class="text-danger ml-2"
        translate>{{error}}</span>
</div>

<form [formGroup]="loginForm"
      class="form-wrapper"
      ccScrollToFirstInvalidInput
      (ngSubmit)="login()">
  <div class="channels-form-group">
    <input formControlName="password"
           id="password"
           type="password"
           class="form-control"
           [type]="hide ? 'password' : 'text'"
           [placeholder]="'PASSWORD' | translate"
           autofocus>
    <mat-icon matSuffix
              (click)="hide = !hide"
              [title]="(hide ? 'CHANNELS.REGISTER.SHOW' : 'CHANNELS.REGISTER.HIDE') | translate"
              [class.active]="!hide">
      {{'visibility'}}</mat-icon>

    <cc-input-errors [form]="loginForm"
                     inputName="password"
                     class="error-wrapper"
                     [errors]="[{'required':'ERROR.REQUIRED'},
                                {'minlength': 'ERROR.PASSWORD'}]"></cc-input-errors>
  </div>
  <button class="channels-button"
          id="login">
    <span translate>LOGIN.LABEL</span>
  </button>
</form>
<a [routerLink]="['/reset', 'email']"
   class="channels-login-forgot-link"
   translate>RESET.FORGOT</a>
