import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { RegisterService } from '../../../shared/register.service';
import { AmplitudeService } from '../../../utils/amplitude.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class DetailsGuard implements CanActivate {
  constructor(private RegisterService: RegisterService,
              private amplitudeService: AmplitudeService,
              private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.amplitudeService.logEvent('SSO: Page View: Registration (details)');
    this.router.navigate(['/register']);
    return false;
  }
}
