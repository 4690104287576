<div *ngIf="!tokenExtinct" class="content">

  <h4>Podaj kod weryfikacyjny</h4>
  <p>Na numer {{maskedPhoneNumber}} wysłaliśmy wiadomość z kodem weryfikacyjnym. Wprowadź go poniżej.</p>

  <form [formGroup]="verificationGroup" class="form" (submit)="onSubmit()">

    <div class="input-error-group">
      <input #input type="text" class="control input" [class.control_error]="errorCode || codeControl.hasError('required') && codeControl.dirty" formControlName="code"
             AllowOnly="numeric" maxlength="4">

      <div class="controls__errors" *ngIf="errorCode">
        <span>Niepoprawny kod. Spróbuj ponownie</span>
      </div>
      <div *ngIf="codeControl.hasError('required') && codeControl.dirty" class="controls__errors">
        <span>Pole jest wymagane</span>
      </div>
    </div>

    <button class="btn btn_bg" [disabled]="loading">Gotowe</button>


    <span (click)="goBack.emit()" class="link go-back-link mt-4">Wróć</span>

  </form>
</div>
<div *ngIf="tokenExtinct" class="token-extinct">
  <img class="icon" src="assets/img/shoper-connect/exclamation.svg" alt="">
  <h4>
    Kod SMS wygasł
  </h4>
  <p>
    Twój kod weryfikacyjny wysłany na numer {{maskedPhoneNumber}} wygasł.
  </p>
  <button  (click)="sendSmsAgain()"  class="btn btn_bg" [disabled]="loading">wyślij SMS jeszcze raz</button>
</div>
