<div class="h-full a-v rlt input-number-hook"
     (click)="preventClickPropagationOutside($event)"
     flex>
  <div class="h-full a-v"
       (click)="dropDown($event)"
       (blur)="isOpenDropDown && dropDown($event)"
       flex>
    <span
      (keydown)="dropDownUseKey($event)"
      tabindex="-1"
      class="phone-number-wrap iti-flag {{ phoneNumberCountryCode }}">
    <div #dropdown
         class="dropdown"
         [ngClass]="{'hidden': !isOpenDropDown}">
      <div class="item a-v text-muted-low"
           flex
           [ngClass]="{'active': isActiveItem(country.alpha2) || isSearchItem(country.alpha2)}"
           (click)="onSelectCountry(country, i)"
           *ngFor="let country of countries; let i = index">
        <div class="country-flag iti-flag {{ country.alpha2.toLowerCase() }}"></div>
        <div class="country-name"
             title="{{ country.name }}">{{ country.name }}</div>
      </div>
    </div>
  </span>
  </div>

  <input
    #phoneNumberInputElement
    class="phone-number dialer form-control"
    [ngClass]="phoneNumberInputClass"
    [class.open-dropdown]="isOpenDropDown"
    name="phoneNumber"
    title="number"
    [value]="phoneNumberInputValue"
    (input)="onChange(phoneNumberInputElement.value)"
    [placeholder]="'PHONE_NUMBER' | translate"
    (click)="onTouched(phoneNumberInputValue)"
    [disabled]="isDisabled"
    id="phone-number"/>
</div>
