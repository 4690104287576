import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {RegisterService} from '../../shared/register.service';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class VerifyPhoneNumberGuard implements CanActivate {
  constructor(private registerService: RegisterService,
              private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.registerService.getUser().phone) {
      this.router.navigate(['/final-register', 'company']);
      return false
    }
    return true;
  }
}
