import { AppConfig } from './app-config';
import { APP_INITIALIZER } from '@angular/core';
import { IConfigResponse } from './app-config.interface';

export const AppInitializer = {
  provide: APP_INITIALIZER, useFactory: AppConfigFactory, deps: [AppConfig], multi: true
};

export function AppConfigFactory(AppConfig: AppConfig): () => Promise<IConfigResponse> {
  return () => AppConfig.initConfig().toPromise();
}
