import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { BACKSPACE, DELETE, ENTER, LEFT_ARROW, RIGHT_ARROW } from './keycodes';

const ALLOWED_CODE = [BACKSPACE, ENTER, DELETE, RIGHT_ARROW, LEFT_ARROW];

@Component({
  selector: 'cc-verify-code',
  templateUrl: './verify-code.component.html',
  styleUrls: ['./verify-code.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class VerifyCodeComponent implements OnInit {
  @Input() verifyCodeLength;
  @Output() changed = new EventEmitter();
  @Input() invalid: boolean = false;
  public inputsLength: number[];
  private backspaceClick = [];
  @ViewChildren('verifyCode') inputs: QueryList<ElementRef>;

  constructor() {
  }

  ngOnInit() {
    this.inputsLength = Array.from(Array(this.verifyCodeLength), (_, i) => i)
  }

  onChangeCode(index, event) {
    const nextElement = this.inputs.toArray()[index + 1];
    const prevElement = this.inputs.toArray()[index - 1];
    if (nextElement && event.target.value && !nextElement.nativeElement.value && ![LEFT_ARROW,RIGHT_ARROW].includes(event.which)) {
      nextElement.nativeElement.focus();
    }
    if (prevElement && !event.target.value && event.which === BACKSPACE) {
      if (this.backspaceClick[index] >= 1) {
        this.backspaceClick = [];
        prevElement.nativeElement.focus();
      } else {
        this.backspaceClick[index] = 1;
      }
    }

    this.checkNumberAndEmit();
  }

  checkNumber(event, index) {
    const nextElement = this.inputs.toArray()[index + 1];
    const prevElement = this.inputs.toArray()[index - 1];
    const keyCode = event.keyCode || event.charCode;

    if (event.target.value.length >= 1 && !ALLOWED_CODE.includes(keyCode)) {
      event.preventDefault();
    }

    if (keyCode === LEFT_ARROW && event.target.selectionStart === 0 && prevElement) {
      prevElement.nativeElement.focus();
    }

    if ( (keyCode === RIGHT_ARROW && event.target.selectionStart === 1 && nextElement)
      || (keyCode === RIGHT_ARROW && event.target.value.length >= 0 && event.target.selectionStart === 0 && nextElement)) {
      nextElement.nativeElement.focus();
    }
  }

  checkNumberAndEmit() {
    const verifyCode = this.inputs.toArray()
      .map((el) => el.nativeElement.value)
      .join('');
      this.changed.next(verifyCode);
  }

}
