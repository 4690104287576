<mat-form-field [appearance]="appearance"
                [formGroup]="parentForm"
                class="form-field"
>
  <mat-label>{{labelKey | translate}}</mat-label>

  <!--suppress HtmlFormInputWithoutLabel -->
  <input type="email"
         [AllowOnly]="allowOnly"
         matInput
         [type]="type"
         [formControlName]="controlName"
         [placeholder]="placeholderKey | translate"
         [required]="required">

  <mat-error *ngFor="let error of errors">
    <ng-container *ngIf="parentForm.hasError(error.key, [controlName]) || parentForm.controls[controlName].hasError(error.key)">
      {{error.messageKey | translate}}
    </ng-container>
  </mat-error>
</mat-form-field>
