import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router} from "@angular/router";
import { ResetService } from "../../reset/reset.service";

@Component({
  selector: 'cc-channels-reset',
  templateUrl: './channels-reset.component.html',
  styleUrls: ['./channels-reset.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ChannelsResetComponent implements OnInit {
  public resetForm: UntypedFormGroup;
  public hide = true;

  constructor(private fb: UntypedFormBuilder,
              private resetService: ResetService,
              private router: Router) {
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.resetForm = this.fb.group(({
      password: ['', Validators.compose([
        Validators.required, Validators.minLength(6)
      ])],
      passwordConfirm: ['', Validators.compose([
        Validators.required, Validators.minLength(6), this.validateAreEqual.bind(this)
      ])]
    }));
  }

  private validateAreEqual(fieldControl: UntypedFormControl) {
    return this.resetForm && fieldControl.value === this.resetForm.get("password").value ? null : {NotEqual: true};
  }

  resetPassword() {
    this.resetService.changePassword(
      this.resetForm.get('password').value,
      this.router.routerState.snapshot.root.queryParams.token).subscribe(() => {
      this.router.navigate(['/reset', 'success']);
    }, () => {
      this.router.navigate(['/reset', 'error']);
    });
  }
}
