<h3 class="channels-details-title" *ngIf="!invited" translate>CHANNELS.REGISTER.TITLE</h3>
<p class="channels-details-description" *ngIf="!invited" translate>CHANNELS.REGISTER.CONTENT</p>

<form [formGroup]="registerForm"
      novalidate
      class="form-wrapper"
      autocomplete="off">

  <div class="channels-form-group password-detail">
    <input #Name formControlName="name"
           class="form-control"
           type="text"
           id="name"
           [placeholder]="'CHANNELS.REGISTER.NAME' | translate"
           autofocus
           data-hj-whitelist
           novalidate>

    <cc-input-errors [form]="registerForm"
                     inputName="name"
                     class="error-wrapper"
                     [errors]="[{'required':'ERROR.REQUIRED'},
                                {'name': 'ERROR.EMAIL'},
                                {'empty':'ERROR.NON_SPACE'}]"></cc-input-errors>
  </div>

  <div class="channels-form-group password-detail">
    <input #Surame formControlName="surname"
           class="form-control"
           type="text"
           id="surname"
           [placeholder]="'CHANNELS.REGISTER.SURNAME' | translate"
           autofocus
           data-hj-whitelist
           novalidate>

    <cc-input-errors [form]="registerForm"
                     inputName="surname"
                     class="error-wrapper"
                     [errors]="[{'required':'ERROR.REQUIRED'},
                                {'name': 'ERROR.EMAIL'},
                                {'empty':'ERROR.NON_SPACE'}]"></cc-input-errors>
  </div>

  <div class="channels-form-group">
    <input #Password formControlName="password"
           class="form-control"
           [type]="hide ? 'password' : 'text'"
           id="password"
           [placeholder]="'PASSWORD' | translate"
           autofocus>
    <mat-icon matSuffix
              (click)="hide = !hide"
              [title]="(hide ? 'CHANNELS.REGISTER.SHOW' : 'CHANNELS.REGISTER.HIDE') | translate"
              [class.active]="!hide">
      {{'visibility'}}</mat-icon>

    <cc-input-errors [form]="registerForm"
                     inputName="password"
                     class="error-wrapper"
                     [errors]="[{'required':'ERROR.REQUIRED'},
                                {'minlength': 'ERROR.PASSWORD'},
                                {'empty':'ERROR.NON_SPACE'}]"></cc-input-errors>
  </div>

  <button class="channels-button"
          id="invite"
          *ngIf="invited"
          (click)="registerWithInvitedDetails()">{{'CHANNELS.REGISTER.NEXT' | translate}}
  </button>

  <button class="channels-button"
          id="register"
          *ngIf="!invited"
          (click)="goToFinal()">{{'CHANNELS.REGISTER.NEXT' | translate}}
  </button>
</form>
