<div class="background">
  <section class="content-wrapper">
    <div class="logo-loader-wrapper">
      <img src="assets/img/shoper-connect/logo.svg"
           alt="Shoper Connect Logo"
           class="logo"
      />
      <span *ngIf="loading" class="a-loader a-loader_l"></span>
    </div>

    <shoper-connect-otp-phone-number *ngIf="currentStep === 'PHONE_NUMBER'"
                                     (submit)="allowToVerify($event)"
                                     [loading]="loading"
                                     [errorCode]="errorCode"

    ></shoper-connect-otp-phone-number>
    <shoper-connect-otp-verification-code *ngIf="currentStep === 'VERIFICATION'"
                                          (submit)="verifyPhoneNumber($event)"
                                          (goBack)="restartFlow()"
                                          (reSend)="allowToVerify({phoneNumber})"
                                          [errorCode]="errorCode"
                                          [loading]="loading"
                                          [phoneNumber]="phoneNumber"></shoper-connect-otp-verification-code>
  </section>
</div>
