<cc-information-header text="VERIFY.HEADER"
                       descriptions="VERIFY.HEADER_DESCRIPTION"
                       [translateParams]="{ number: number }"
                       class="mx-4 d-block"></cc-information-header>
<form [formGroup]="verifyForm"
      class="mt-5"
      (ngSubmit)="register()"
      novalidate>
  <div *ngIf="errorTexts"
       class="verify-error small text-danger mb-3">
    <span translate> {{errorTexts.CONTENT}}</span>
    <a (click)="contactSupport()"
       *ngIf="errorTexts.SHOW_SUPPORT"
       class="text-danger pointer"
       translate>SUPPORT.CONTACT</a>
  </div>
  <cc-verify-code [verifyCodeLength]="verifyCodeLength"
                  [invalid]="errorTexts"
                  (changed)="onChange($event)"></cc-verify-code>
  <div class="mt-4 resend text-muted text-sm _300">
    <a class="text-danger"
       (click)="resend()"
       translate>VERIFY.RESEND</a>
    <span translate>VERIFY.RESEND_1</span>
    <a class="text-danger"
       [routerLink]="chanePhoneNumberUrl"
       translate>VERIFY.RESEND_2</a>
    <span translate>VERIFY.RESEND_3</span>
  </div>
  <button class="btn btn-danger btn-lg w-100 _300 radius d-block mt-5 text-uppercase"
          id="register"
          translate
          [disabled]="verifyForm.invalid || verifyForm.pending">REGISTER.START
  </button>
</form>
<cc-policy></cc-policy>
