import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RegisterService} from '../../shared/register.service';
import {RegisterHelperService} from '../register-helper.service';
import {LiveChatService} from '../../utils/live-chat.service';

@Component({
  selector: 'cc-verify-phone-number',
  templateUrl: './verify-phone-number.component.html',
  styleUrls: ['./verify-phone-number.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class VerifyPhoneNumberComponent implements OnInit {
  public verifyForm: UntypedFormGroup;
  public number: string;
  public verifyCodeLength = 4;
  public chanePhoneNumberUrl: string;
  public errorTexts: any;

  constructor(private fb: UntypedFormBuilder,
              private registerHelperService: RegisterHelperService,
              private liveChatService: LiveChatService,
              private registerService: RegisterService) {
  }

  ngOnInit() {
    this.number = this.registerService.getUser().phone;
    this.createForm();
    this.chanePhoneNumberUrl = this.registerService.chanePhoneNumberUrl;
  }

  createForm() {
    this.verifyForm = this.fb.group(({
      verificationCode: ['', [
        Validators.required,
        Validators.minLength(this.verifyCodeLength),
        Validators.maxLength(this.verifyCodeLength)
      ]]
    }));
  }

  register() {
    this.verifyForm.markAsPending();
    this.registerService.updateUser(this.verifyForm.getRawValue());

    this.registerService.register(
      () => this.registerHelperService.resolveRegistrationType(),
      '/final-register/error',
      () => {
        this.errorTexts = this.registerService.errorTexts
      }
    );
  }

  onChange(verificationCode) {
    this.verifyForm.setValue({verificationCode});
    this.errorTexts = null;
  }

  contactSupport() {
    this.liveChatService.contactSupport();
  }

  resend() {
    this.registerService.verify();
  }
}
