<div class="welcome-text" *ngIf="showView" translate>LIVECHAT.WELCOME</div>

<div class="wrapper" *ngIf="showView">
  <div class="content">
    <div class="logo">
      <img src="/assets/img/channels/logo.png" height="80">
      <span class="text">Channels</span>
    </div>

    <div class="description">
      <div translate>LIVECHAT.DESCRIPTION</div>
      <div class="functions-description" translate>LIVECHAT.FUNCTIONS_DESCRIPTION</div>
      <cc-functions-description text="LIVECHAT.FUNCTION_1"></cc-functions-description>
      <cc-functions-description text="LIVECHAT.FUNCTION_2"></cc-functions-description>
      <cc-functions-description text="LIVECHAT.FUNCTION_3"></cc-functions-description>
      <cc-functions-description text="LIVECHAT.FUNCTION_4"></cc-functions-description>
      <div translate>LIVECHAT.MUCH_MORE</div>
      <div class="free-account-text" translate>LIVECHAT.CREATE_FREE_ACCOUNT</div>
    </div>

    <div class="actions">
      <button (click)="startUsingChannels()" translate>LIVECHAT.START_USING_CHANNELS</button>
      <a (click)="connectWithLiveChat()">
        <span translate>LIVECHAT.CONNECT_WITH_LIVECHAT</span>
        <i class="material-icons">arrow_forward_black</i>
      </a>
    </div>
  </div>

  <div class="images">

  </div>
</div>

