import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidateDomain } from '../shared/validators/domain/unique-domain';
import { ValidateUniqueEmail } from '../shared/validators/email/unique-email';
import {
  specialCharactersAccount,
  ValidateEmptyCharacters
} from '../shared/validators/account/special-characters-account';
import { EmailService } from '../shared/validators/email/email.service';
import { AccountService } from '../shared/validators/account/account.service';
import { RegisterService } from '../shared/register.service';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import { AppConfig } from '../config/app-config';
import { SocialRegisterData } from './register-social/register-social.type';

@Injectable()
export class RegisterHelperService {

  private formFields = {
    email: ['', [
      Validators.required, Validators.pattern(this.AppConfig.emailPattern), ValidateDomain(), ValidateEmptyCharacters()
    ], ValidateUniqueEmail(this.EmailService)],
    emailChannels: ['', [Validators.required, Validators.pattern(this.AppConfig.emailPattern), ValidateDomain(), ValidateEmptyCharacters()]],
    name: ['', [Validators.required, ValidateEmptyCharacters()]],
    surname: ['', [Validators.required, ValidateEmptyCharacters()]],
    accountName: ['',[Validators.required, specialCharactersAccount(), ValidateEmptyCharacters()]],
    password: ['',[
      Validators.required, Validators.minLength(6)
    ]],
    phone: '',
    website: '',
    companySize: ['', Validators.required]
  };

  constructor(private fb: UntypedFormBuilder,
              private AccountService: AccountService,
              private EmailService: EmailService,
              private router: Router,
              private RegisterService: RegisterService,
              private AppConfig: AppConfig,
              private LoginService: LoginService) {
  }

  createForm(fields: Array<string>) {
    const formFields = {};
    fields.forEach((field) => {
      formFields[field] = this.formFields[field];
    });

    return this.fb.group((formFields));
  }

  updateForm(form: UntypedFormGroup) {
    const user = this.RegisterService.getUser();
    Object.keys(form.value).forEach(field => {
      form.controls[field].setValue((user[field === 'emailChannels' ? 'email' : field] || '').trim());
    });
  }

  resolveRegistrationType() {
    const registerType = this.RegisterService.getRegisterType();
    if (registerType) {
      this.RegisterService.trackEvent('sso-registration-' + registerType.name);
      window.location.href = this.registerRedirectUrl(registerType);
    } else {
      this.regularLogin();
    }
  }

  async socialLogin() {
    const registerType = this.RegisterService.getRegisterType();
    if (registerType) {
      window.location.href = this.registerRedirectUrl(registerType);
      return;
    }
    return Promise.resolve();
  }

  private registerRedirectUrl(registerType: SocialRegisterData) {
    const { email } = this.RegisterService.getUser();
    return registerType.shouldAttachEmailParam ? `${registerType.url}?email=${email}` : registerType.url;
  }

  regularLogin() {
    if (this.RegisterService.userExists() && !this.RegisterService.getPassword()) {
      this.RegisterService.trackEvent('sso-registration-instance');

      this.router.navigateByUrl('/login');
    } else {
      this.RegisterService.trackEvent('sso-registration-email');
      this.LoginService.loginUser();
    }
  }
}
