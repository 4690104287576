import { Component, Input} from '@angular/core';

@Component({
  selector: 'cc-information-header',
  templateUrl: './information-header.component.html',
  styleUrls: ['./information-header.component.scss']
})
export class InformationHeaderComponent {
  @Input() text: string;
  @Input() descriptions: string;
  @Input() translateParams: Object;
}
