import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ShoperConnectService } from "./shoper-connect.service";
import { ActivatedRoute } from "@angular/router";
import { environment } from "../../environments/environment";

@Component({
  selector: "cc-register-shoper-connect",
  templateUrl: "./register-shoper-connect.component.html",
  styleUrls: ["./register-shoper-connect.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class RegisterShoperConnectComponent implements OnInit {
  accountNotCreated = false;
  receivedResponse = false;

  constructor(
    private shoperConnectService: ShoperConnectService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {

    const redirectUri = this.route.snapshot.queryParams["redirect_uri"];
    const shop = this.route.snapshot.queryParams["shop"];
    const application = this.route.snapshot.queryParams["application"];
    this.shoperConnectService
      .registerInstance(this.route.snapshot.queryParams)
      .subscribe(
        (result) => {
          if(result) {
            window.location.href = `${redirectUri}?access_token=${result.accessToken}&shop=${shop}&application=${application}`;
          } else if (result === null) {
            this.receivedResponse = true
          }
        },
        (error) => {
          if (error.status === 409) {
            this.accountNotCreated = true
          }
          this.receivedResponse = true;
        }
      );
  }

  // TODO: fix SSO frontend build process to NOT overwrite qa13 build dist by prod
  goToManagerPanel() {
    // TODO: fix SSO frontend build process to NOT overwrite qa13 build dist by prod
    const baseUrl =
      window.location.hostname === "qa13-sso.aws-whitelabel.ch"
        ? "https://qa13-app.aws-whitelabel.ch"
        : environment.SHOPER_CONNECT_URL;

    window.open(baseUrl + window.location.search);
  }

  openMobileStore(system: "android" | "iOS") {
    window.open(`https://getsc.pl/${system}`, "_blank");
  }
  reloadFirstSteps() {
    window.location.reload();
  }
}
