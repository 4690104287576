import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {RegisterService} from '../../shared/register.service';
import {EmailService} from '../../shared/validators/email/email.service';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';

@Injectable()
export class VerifyGuard implements CanActivate {
  constructor(private RegisterService: RegisterService,
              private router: Router,
              private EmailService: EmailService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const params = state.root.queryParams;
    this.RegisterService.updateUser({ email: params.email, token: params.token });

    if (params.email) {
      console.log('guard email: ' + params.email);
      return this.EmailService.checkEmailNotExists(params.email).pipe(
        map(exists => {
          this.router.navigateByUrl(exists ? '/login' : '/register-switch');
          return false;
        })
      );
    } else {
      console.log('guard navigate');
      this.router.navigate(['/login']);
      return false;
    }
  }
}
