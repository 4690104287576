export enum verificationPhoneNumberErrors {
  TWILIO_EXCEPTION = 'TWILIO_EXCEPTION',
  MSISDN_VERIFY_ATTEMPTS_EXCEEDED = 'MSISDN_VERIFY_ATTEMPTS_EXCEEDED',
  MSISDN_BLOCKED = 'MSISDN_BLOCKED',
  FAIL_SEND_SMS_TO_LANDLINE = 'FAIL_SEND_SMS_TO_LANDLINE',
  INVALID_VERIFICATION_CODE = 'INVALID_VERIFICATION_CODE',
  NO_PENDING_VERIFICATION = 'NO_PENDING_VERIFICATION'
}

export const INVITATION_DOESNT_EXISTS = 'INVITATION_DOESNT_EXISTS';

export const ERROR_TO_CODE = {
  IP_ON_BLACKLIST: 10,
  ALL_POOL_OCCUPIED: 11,
  UNKNOWN_ERROR: 12,
  INVALID_EMAIL: 14,
  LOCALHOST_ERROR: 15,
  EMAIL_CONFLICT: 16,

  [INVITATION_DOESNT_EXISTS]: 17,

  REGISTRATION_INVALID_SPECIAL_CHARACTERS: 18,

  POTENTIAL_EMAIL_FRAUD: 21,
  POTENTIAL_IP_FRAUD: 22,

  [verificationPhoneNumberErrors.TWILIO_EXCEPTION]: 81,
  [verificationPhoneNumberErrors.MSISDN_VERIFY_ATTEMPTS_EXCEEDED]: 82,
  [verificationPhoneNumberErrors.MSISDN_BLOCKED]: 83,
  [verificationPhoneNumberErrors.FAIL_SEND_SMS_TO_LANDLINE]: 84,

  [verificationPhoneNumberErrors.INVALID_VERIFICATION_CODE]: 85,
  [verificationPhoneNumberErrors.NO_PENDING_VERIFICATION]: 86
};


export const ERROR_TEXTS = {
  [ERROR_TO_CODE.UNKNOWN_ERROR]: {
    TITLE: 'ERROR.REGISTER',
    CONTENT: 'SUPPORT.TRY_AGAIN',
    SHOW_SUPPORT: true
  },
  [INVITATION_DOESNT_EXISTS]: {
    TITLE: 'ERROR.INVITATION_ERROR',
    CONTENT: 'ERROR.INVITATION_DOESNT_EXISTS',
    SHOW_SUPPORT: false
  },
  EMAIL_CONFLICT: {
    TITLE: 'ERROR.UNABLE_LOGIN_EMAIL',
    CONTENT: 'SUPPORT.EMAIL_CONFLICT',
    SHOW_SUPPORT: true
  },
  [verificationPhoneNumberErrors.TWILIO_EXCEPTION]: {
    TITLE: 'ERROR.VERIFICATION',
    CONTENT: 'VERIFY.TWILIO_EXCEPTION',
    SHOW_SUPPORT: true
  },
  [verificationPhoneNumberErrors.MSISDN_VERIFY_ATTEMPTS_EXCEEDED]: {
    TITLE: 'ERROR.VERIFICATION',
    CONTENT: 'VERIFY.MSISDN_VERIFY_ATTEMPTS_EXCEEDED',
    SHOW_SUPPORT: true
  },
  [verificationPhoneNumberErrors.MSISDN_BLOCKED]: {
    TITLE: 'ERROR.VERIFICATION',
    CONTENT: 'VERIFY.MSISDN_BLOCKED',
    SHOW_SUPPORT: true
  },
  [verificationPhoneNumberErrors.FAIL_SEND_SMS_TO_LANDLINE]: {
    TITLE: 'ERROR.VERIFICATION',
    CONTENT: 'VERIFY.FAIL_SEND_SMS_TO_LANDLINE',
    SHOW_SUPPORT: true
  },
  [verificationPhoneNumberErrors.INVALID_VERIFICATION_CODE]: {
    TITLE: 'ERROR.VERIFICATION',
    CONTENT: 'VERIFY.INVALID_VERIFICATION_CODE',
  },
  [verificationPhoneNumberErrors.NO_PENDING_VERIFICATION]: {
    TITLE: 'ERROR.VERIFICATION',
    CONTENT: 'VERIFY.NO_PENDING_VERIFICATION',
  }
};
