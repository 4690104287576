import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '../utils/utils.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from "../shared/shared.module";
import { LivechatComponent } from "./livechat.component";
import { FunctionsDescriptionComponent } from './functions-description/functions-description.component';

@NgModule({

  imports: [
    CommonModule,
    UtilsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    SharedModule,
  ],
  providers: [],
  declarations: [LivechatComponent, FunctionsDescriptionComponent],
  exports: []

})
export class LivechatModule { }
