import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UtilsModule } from "../utils/utils.module";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "../shared/shared.module";

import { RegisterShoperConnectComponent } from "./register-shoper-connect.component";
import { OtpComponent } from './otp/otp.component';
import { OtpPhoneNumberComponent } from './otp/otp-phone-number/otp-phone-number.component';
import { OtpVerificationCodeComponent } from './otp/otp-verification-code/otp-verification-code.component';

@NgModule({
  imports: [
    CommonModule,
    UtilsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    SharedModule,
  ],
  providers: [],
  declarations: [RegisterShoperConnectComponent, OtpComponent, OtpPhoneNumberComponent, OtpVerificationCodeComponent],
  exports: [],
})
export class ShoperConnectModule {}
