import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ResetService } from '../reset.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class ResetTokenGuard implements CanActivate {
  constructor(private ResetService: ResetService,
              private router: Router) {
  };

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(resolve => this.ResetService.verifyToken(state.root.queryParams.token)
      .subscribe(
        () => resolve(true),
        () => {
          this.router.navigate(['reset', 'error']) && resolve(false);
        })
    );
  }
}
