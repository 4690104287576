import {IConfigResponse} from './app-config.interface';

export const appConfigDefault:IConfigResponse = {
  facebookAppId: '227306577465710',
  livechatClientId: '8f34ce9fe2bca5c2c4556f46bf1ef842',
  liveChatLicense: 6849311,
  googleApiKey: '1041188948998-23qffm3nmknko0kbs4h90sktahd5ulbp.apps.googleusercontent.com',
  amplitudeApiKey: 'b422e34781ba48767f0a447755772a21',
  sentryEnabled: false,
  shoperAmplitudeApiKey: '648de99bbd9a199271c8fdb5b479dd42'
};
