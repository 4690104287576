import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterHelperService } from './register-helper.service';
import { AccountService } from '../shared/validators/account/account.service';
import { EmailService } from '../shared/validators/email/email.service';
import { UtilsModule } from '../utils/utils.module';
import { ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { RegisterService } from '../shared/register.service';
import { PolicyComponent } from './policy/policy.component';
import { RegisterRequestsService } from './register-requests.service';
import { SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { AppConfig } from '../config/app-config';
import { provideSocialAuthService } from './register-social/register-social.service.provider';
import { InviteGuard } from './guards/invite.guard';
import { DetailsGuard } from './register-3-steps/guards/details.guard';
import { CompanyGuard } from './register-3-steps/guards/company.guard';
import { EmailGuard } from './register-3-steps/guards/email.guard';
import { ErrorGuard } from './guards/error.guard';
import { TranslateModule } from '@ngx-translate/core';
import { VerifyPhoneNumberComponent } from './verify-phone-number/verify-phone-number.component';
import { VerifyCodeComponent } from './verify-phone-number/verify-code/verify-code.component';
import { VerifyPhoneNumberService } from './verify-phone-number/verify-phone-number.service';
import { VerifyPhoneNumberGuard } from './verify-phone-number/verify-phone-number.guard';
import { NgProgressModule } from 'ngx-progressbar';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  imports: [
    CommonModule,
    UtilsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    SocialLoginModule,
    NgProgressModule,
    SharedModule,
    MatRadioModule
  ],
  declarations: [
    PolicyComponent,
    VerifyPhoneNumberComponent,
    VerifyCodeComponent
  ],
  exports: [
    PolicyComponent
  ],
  providers: [
    AccountService,
    EmailService,
    RegisterHelperService,
    RegisterService,
    RegisterRequestsService,
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: provideSocialAuthService ,
      deps: [AppConfig]
    },
    InviteGuard,
    EmailGuard,
    DetailsGuard,
    CompanyGuard,
    ErrorGuard,
    VerifyPhoneNumberService,
    VerifyPhoneNumberGuard
  ]
})
export class RegisterModule {
}

