export type SocialRegisterProvider = 'FACEBOOK' | 'GOOGLE' | 'LIVECHAT' | 'APPLE'

export const REGISTER_TYPE: { [provider in SocialRegisterProvider]: SocialRegisterData & SocialButtonData } = {
  FACEBOOK: {
    url: '/login/facebook',
    name: 'facebook',
  },
  GOOGLE: {
    url: '/login/google',
    name: 'google',
    shouldAttachEmailParam: true,
    imageSrc: 'assets/img/google.png'
  },
  LIVECHAT: {
    url: '/login/livechat',
    name: 'liveChat',
    imageSrc: 'assets/img/livechat.svg'
  },
  APPLE: {
    url: '/login/apple',
    name: 'apple',
    imageSrc: 'assets/img/apple.png'
  }
};

export interface SocialRegisterData {
  url: string;
  name: string;
  shouldAttachEmailParam?: boolean;
  [others: string]: any;
}

export interface SocialButtonData {
  imageSrc?: string;
  buttonId?: string;
}
