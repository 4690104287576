import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {RegisterService} from '../../shared/register.service';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class ErrorGuard implements CanActivate {
  constructor(private RegisterService: RegisterService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.RegisterService.errorCode) {
      this.router.navigate(['/login']);
      return false
    }
    return true;
  }
}
