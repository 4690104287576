import {CookieService} from "ngx-cookie-service";
import {Injectable} from "@angular/core";

const gaCookie: string = '_ga';

@Injectable()
export default class GaService {

  constructor(private cookieService: CookieService)  {}


    addGACookie(user) { user.gaCookie = this.cookieService.get(gaCookie) || 'ga cookie not exist';
  }
}
