import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import { UntypedFormControl } from '@angular/forms';

export function isValidPhoneNumber(phoneNumber: string) {
  if (phoneNumber.startsWith('00')) {
    phoneNumber = `+${phoneNumber.slice(2)}`;
  }
  if (!phoneNumber.startsWith('+')) {
    phoneNumber = `+${phoneNumber}`;
  }
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
  return parsedPhoneNumber && parsedPhoneNumber.isValid();
}

export function onlyNumbers(control: UntypedFormControl): { [key: string]: any } {
  const value: string = control.value || '';
  const valid = value.toString().match(/^\d+$/);
  return valid ? null : { numbers: true };
}

export function isEmptyOrValidPhoneNumber(phoneNumber: string) {
  return !phoneNumber || isValidPhoneNumber(phoneNumber);
}
