<div class="form-group input-group-lg">
  <input class="form-control"
         *ngFor="let i of inputsLength"
         [ngClass]="{'ng-invalid ng-touched': invalid}"
         autocomplete="new-verifyCode"
         (keyup)="onChangeCode(i, $event)"
         (keydown)="checkNumber($event, i)"
         #verifyCode
         id="verify-code">
</div>
