import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {RegisterService} from '../../shared/register.service';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, map} from 'rxjs/operators';
import {INVITATION_DOESNT_EXISTS} from '../register.config';
import {of} from "rxjs";


@Injectable()
export class InviteGuard implements CanActivate {
  constructor(private RegisterService: RegisterService, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const params = state.root.queryParams;
    this.RegisterService.updateUser({token: params.token});

    if (!params.token) {
      this.router.navigate(['/register']);
      return false;
    }

    return this.RegisterService.inviteValidateToken(params.token).pipe(
      catchError(() => {
        this.RegisterService.onRegisterError([INVITATION_DOESNT_EXISTS], '/final-register/error');
        return of(false);
      }),
      map(() => {
          this.RegisterService.trackEvent('sso-step1-invite');
          return true;
        }
      )
    );
  }
}
