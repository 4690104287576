import { Routes } from "@angular/router";
import { RegisterShoperConnectComponent } from "./register-shoper-connect.component";
import { OtpComponent } from "./otp/otp.component";

export const shoperConnectRoutes: Routes = [
  {
    path: "shoper-connect",
    component: RegisterShoperConnectComponent,
  },
  {
    path: "otp",
    component: OtpComponent,
  },
];
